import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./index.module.css";
import {
  errorsReducer,
  errorsSelectors,
} from "../../../../../store/auxiliary/errorsReducer";
import { useCodeFieldLogic } from "../../../../ui/CodeField/useCodeFieldLogic";
import { API } from "../../../../../api";
import { CodeField } from "../../../../ui/CodeField";
import { Button } from "../../../../ui/Button";
import { Title } from "../../../../ui/Title";
import { Subtitle } from "../../../../ui/Subtitle";
import { useHistory, useParams } from "react-router-dom";
import { MobilePageContainer } from "../../../../ui/Containers/MobilePageContainer";
import { Loader } from "components/ui/Loader";
import { Icons } from "../../../../../assets/icons";
import { routes } from "../../../../../routes";
import { stringHelpers } from "../../../../../helpers/string";
import { downloadFile } from "../../../../../helpers/file";
import { AxiosResponse } from "axios";
import { CabinetErrorPage } from "../Statuses/CabinetErrorPage";
import { LoadingPage } from "../../../Application/pages/LoadingPage";
import { CodeFieldOneInput } from "../../../../ui/CodeFieldOneInput";
import { SmartForm } from "../../../../hocs/SmartForm";
import { useForm, useWatch } from "react-hook-form";
import { linksToDocumentsOnStatic } from "../../../../../helpers/init/constantStrings";
import { Checkbox } from "../../../../ui/Checkbox";
import {
  routingAction,
  routingSaga,
} from "../../../../../sagas/util/helpers/routingSaga";
import { processLoading } from "../../../../../store/auxiliary/loadingReducer";
import { authSagaActions } from "../../../../../sagas/auth/actions";

/*
  Страница во многом повторяет <Code /> из заявки
 */

export const EarlyRepaymentCode: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const form = useForm();

  const loadingAutoAuth = useSelector(
    processLoading.get(authSagaActions.autoAuth.type)
  );

  const [isSendCodeLoading, setIsSendCodeLoading] = useState(true);
  const [isVerifyCodeLoading, setIsVerifyCodeLoading] = useState(false);
  const [showErrorPage, setShowErrorPage] = useState(false);
  const [isLoadingDownloadDocument, setIsLoadingDownloadDocument] =
    useState(false);
  const [isChecked, setIsChecked] = useState(true);

  const { early_repayment_id, loan_id, amount } = useParams<{
    early_repayment_id: string;
    loan_id: string;
    amount: string;
  }>();

  const code: `${number}` | undefined = useWatch({
    control: form.control,
    name: "code",
  });

  const errors = useSelector(errorsSelectors.fieldsErrors);
  const codeField = useCodeFieldLogic({
    codeLength: 4,
    onChange: () => {
      dispatch(errorsReducer.actions.removeFieldError("code"));
    },
  });

  const sendCode = () => {
    if (early_repayment_id && loan_id) {
      setIsSendCodeLoading(true);
      API.main.application
        .getPartialEarlyRepaymentCode({
          loan_id,
          early_repayment_id,
        })
        .then((res) => {
          if (res.status === 201) {
            setIsSendCodeLoading(false);
          } else {
            setShowErrorPage(true);
          }
        });
    }
  };

  useEffect(() => {
    sendCode();
  }, []);

  // Доки формируются секунд 7, для этого пингуем, пока не улетит запрос с 200
  const downloadDocument = () => {
    setIsLoadingDownloadDocument(true);
    let interval = setInterval(() => {
      API.main.application
        .downloadDocument({
          early_repayment_id,
          loan_id,
          file_type: "early_repayment_application",
        })
        .then((res: AxiosResponse<any>) => {
          if (res.status === 200) {
            downloadFile(
              res?.data?.data?.content,
              "Заявление на досрочное погашение",
              "_blank"
            );
            setIsLoadingDownloadDocument(false);
            clearInterval(interval);
          }
        });
    }, 3000);
  };

  // значение кода лежит в codeField.value
  const confirm = () => {
    setIsVerifyCodeLoading(true);
    if (early_repayment_id && loan_id) {
      API.main.application
        .verifyPartialEarlyRepaymentCode({
          loan_id,
          early_repayment_id,
          code: code!,
        })
        .then((res) => {
          if (res.status === 200) {
            setIsVerifyCodeLoading(false);
            history.push(
              routes.cabinet.statuses.repayment_signing.root({
                loan_id,
                early_repayment_id,
                amount,
              })
            );
          }
        })
        .catch((res) => {
          dispatch(
            errorsReducer.actions.setFieldsErrors({
              code: "Неверный код!",
            })
          );
          setIsVerifyCodeLoading(false);
        });
    }
  };

  if (showErrorPage) return <CabinetErrorPage />;

  // * тут нужно поменять текста в зависимости от variant
  return (
    <MobilePageContainer className={styles.wrapper}>
      {loadingAutoAuth ? (
        <Loader variant={"large"} className={styles.loader} />
      ) : (
        <>
          {isSendCodeLoading ? (
            <>
              <div className={styles.container}>
                <Title className={styles.title}>Подпишите заявление</Title>

                {/*<Subtitle className={styles.subtitle}>*/}
                {/*  Сумма {stringHelpers.transformMoneyValue(amount)}{" "}*/}
                {/*  <Icons.ui.Ruble />*/}
                {/*</Subtitle>*/}

                <SmartForm submit={() => console.log()} form={form}>
                  <div className={styles.code}>
                    <CodeFieldOneInput
                      sendCodeHandler={sendCode}
                      retryCount={codeField.retryCount}
                      setRetryCount={codeField.setRetryCount}
                    />
                  </div>
                </SmartForm>

                {/*<CodeField*/}
                {/*  {...codeField}*/}
                {/*  sendCodeHandler={sendCode}*/}
                {/*  error={errors?.code}*/}
                {/*  className={styles.codeField}*/}
                {/*  retryCount={codeField.retryCount}*/}
                {/*  setRetryCount={codeField.setRetryCount}*/}
                {/*test*/}
                {/*/>*/}
                <div className={styles.retry_count}>
                  Попытка {codeField.retryCount} из 5
                </div>

                {/*<div className={styles.pers_data}>*/}
                {/*  Подписывая{" "}*/}
                {/*  {isLoadingDownloadDocument ? (*/}
                {/*    <Loader children={"заявление"} />*/}
                {/*  ) : (*/}
                {/*    <span*/}
                {/*      onClick={downloadDocument}*/}
                {/*      className={styles.pers_data_link}*/}
                {/*    >*/}
                {/*      заявление*/}
                {/*    </span>*/}
                {/*  )}{" "}*/}
                {/*  Вы соглашаетесь <br />с правилами досрочного погашения*/}
                {/*</div>*/}
                <Button
                  disabled={!code || code.length < 4 || !isChecked}
                  onClick={confirm}
                  className={styles.button}
                  loading={isVerifyCodeLoading}
                >
                  Подписать
                </Button>
              </div>
              <div className={styles.confirm_text}>
                Введя код из СМС, вы подписываете <br /> следующие документы:
              </div>
              <SmartForm submit={() => console.log()} form={form}>
                <div className={styles.notes_block}>
                  <Checkbox
                    name={"pd"}
                    className={styles.mr_check_box}
                    onChange={() => setIsChecked((prev) => !prev)}
                    checked={isChecked}
                    blockValue={true}
                  />
                  <div
                    className={styles.pdf_title_flex}
                    onClick={
                      isLoadingDownloadDocument ? () => {} : downloadDocument
                    }
                  >
                    {isLoadingDownloadDocument ? (
                      <Loader className={styles.loader_document} />
                    ) : (
                      <div>
                        <Icons.ui.PdfLogo />
                      </div>
                    )}
                    <div className={styles.notes_block_text}>
                      Заявление на досрочное погашение потребительского
                      микрозайма
                    </div>
                  </div>
                </div>
              </SmartForm>
            </>
          ) : (
            // <LoadingPage
            //   title={""}
            //   subtitle={
            //     <>
            //       Мы загружаем экран оплаты.
            //       <br /> Пожалуйста, подождите!
            //     </>
            //   }
            //   additionalSubtitle={
            //     <>
            //       Оплата будет доступна
            //       <br /> через несколько секунд
            //     </>
            //   }
            //   countdownTime={30}
            // />
            <>
              <div className={styles.container}>
                <Title className={styles.title}>Подпишите заявление</Title>

                {/*<Subtitle className={styles.subtitle}>*/}
                {/*  Сумма {stringHelpers.transformMoneyValue(amount)}{" "}*/}
                {/*  <Icons.ui.Ruble />*/}
                {/*</Subtitle>*/}

                {/*<CodeField*/}
                {/*  {...codeField}*/}
                {/*  sendCodeHandler={sendCode}*/}
                {/*  error={errors?.code}*/}
                {/*  className={styles.codeField}*/}
                {/*  retryCount={codeField.retryCount}*/}
                {/*  setRetryCount={codeField.setRetryCount}*/}
                {/*/>*/}

                <SmartForm submit={() => console.log()} form={form}>
                  <div className={styles.code}>
                    <CodeFieldOneInput
                      sendCodeHandler={sendCode}
                      retryCount={codeField.retryCount}
                      setRetryCount={codeField.setRetryCount}
                    />
                  </div>
                </SmartForm>

                <div className={styles.retry_count}>
                  Попытка {codeField.retryCount} из 5
                </div>

                {/*<div className={styles.pers_data}>*/}
                {/*  Подписывая{" "}*/}
                {/*  {isLoadingDownloadDocument ? (*/}
                {/*    <Loader children={"заявление"} />*/}
                {/*  ) : (*/}
                {/*    <span*/}
                {/*      onClick={downloadDocument}*/}
                {/*      className={styles.pers_data_link}*/}
                {/*    >*/}
                {/*      заявление*/}
                {/*    </span>*/}
                {/*  )}{" "}*/}
                {/*  Вы соглашаетесь <br />с правилами досрочного погашения*/}
                {/*</div>*/}
                <Button
                  disabled={!code || code.length < 4 || !isChecked}
                  onClick={confirm}
                  className={styles.button}
                  loading={isVerifyCodeLoading}
                >
                  Подписать
                </Button>
              </div>
              <div className={styles.confirm_text}>
                Введя код из СМС, вы подписываете <br /> следующие документы:
              </div>
              <SmartForm submit={() => console.log()} form={form}>
                <div className={styles.notes_block}>
                  <Checkbox
                    name={"pd"}
                    className={styles.mr_check_box}
                    onChange={() => setIsChecked((prev) => !prev)}
                    checked={isChecked}
                    blockValue={true}
                  />
                  <div
                    className={styles.pdf_title_flex}
                    onClick={
                      isLoadingDownloadDocument ? () => {} : downloadDocument
                    }
                  >
                    {isLoadingDownloadDocument ? (
                      <Loader className={styles.loader_document} />
                    ) : (
                      <div>
                        <Icons.ui.PdfLogo />
                      </div>
                    )}
                    <div className={styles.notes_block_text}>
                      Заявление на досрочное погашение потребительского
                      микрозайма
                    </div>
                  </div>
                </div>
              </SmartForm>
            </>
          )}
        </>
      )}
    </MobilePageContainer>
  );
};
