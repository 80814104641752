import { PayloadAction } from "@reduxjs/toolkit";
import { call, delay, put } from "redux-saga/effects";
import axios from "axios";
import { API } from "../../../api";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import { processStateController } from "../../util/helpers/processStateController";
import { redirect } from "../../util/helpers/redirect";
import { sagaApiType } from "../../util/types";
import { lsController } from "../../../store/auxiliary/localStorage";
import { applicationReducer } from "../../../store/reducers/application";
import { authSagaActions } from "../actions";
import { routes } from "../../../routes";
import { sendMetrics } from "../../../services/yandexMetrika";

export function* getCarSaga({
  payload,
  type: actionType,
}: PayloadAction<string>) {
  const process = processStateController(actionType);
  try {
    yield process.start();
    yield delay(1);

    const key = payload.length > 9 ? "vin" : "grz";

    lsController.set(key, payload);
    const vinOrGrz = lsController.get(key);

    let response: sagaApiType;
    let retries = 30;

    while (retries > 0) {
      try {
        response = yield call(API.main.services.getCar, {
          [key === "grz" ? "reg_number" : "vin"]: vinOrGrz,
        });

        if (response.status === 200 && response?.data?.data?.is_valid) {
          yield put(
            applicationReducer.actions.setCarBody({
              car_body: response?.data.data,
            })
          );
          yield redirect(routes.auth.phone);
          sendMetrics("check auto");
          lsController.set("cost", response?.data?.data?.cost);
          return;
        } else if (response.status === 204) {
          retries--;
          yield delay(2000);
        } else {
          yield put(
            applicationReducer.actions.setCarBody({
              car_body: response?.data.data,
            })
          );
          yield redirect(routes.auth.wrong_vehicle);
          yield process.stop();
          return;
        }
      } catch (error) {
        retries = 0;
        lsController.clearGRZ();
        lsController.clearVIN();

        if (axios.isAxiosError(error)) {
          yield redirect(
            error?.response?.status === 400
              ? routes.auth.vehicle_not_found
              : routes.auth.vehicle_error
          );
        }
      }
    }
  } catch (e: any) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: authSagaActions.getCar.type,
    });
  } finally {
    yield process.stop();
  }
}
