import { createFC } from "helpers/createFC";
import styles from "./index.module.css";
import { motion } from "framer-motion";
import { backgroundAnimation, burgerAnimation } from "./animate";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Icons } from "assets/icons";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { applicationReducerSelectors } from "../../../store/reducers/application";
import { routes } from "../../../routes";
import { useHistory, useLocation } from "react-router-dom";
import { constantStrings } from "../../../helpers/init/constantStrings";
import { ReactComponent as Radio_checked } from "../../../assets/icons/ui/Radio_checked.svg";
import { userReducerSelectors } from "../../../store/reducers/user/selectors";
import { applicationStatusType } from "../../../types/application/types";
import { logout } from "../../../store/reducers/user";
import packageJson from "../../../../package.json";
import { sendMetrics } from "../../../services/yandexMetrika";

type propsType = {
  isBurgerOpen: boolean;
  setIsBurgerOpen: Dispatch<SetStateAction<boolean>>;
};

/*
создание  заявки:
    - new,
  + заявки еще  нет(POST application не  отправляли)

Фото документов
  - need_document_correction_filling,
  - document_filling,

Отправка заявки
    - recognize_documents_process,

Проверка данных
  - passport_filling,

Фото автомобиля
    - car_photo_filling,

Решение по займу
  - order_filling,
  - scoring_process,
  - sent_process,

Оформление займа
  - issue_method_filling
  - issued_final,
  - signed_issuing_process,
  - signing_applying_agreement_filling,
  - signing_loan_contract_filling,

В зависимости  от того, с  какиго статуса в него перешли (т.е. если с     - recognize_documents_process, то "Отправка заявки")
  - refusal_final,
  - user_refused_final
  - error_final,

соответствие  статусов  прогрес бару  и  бургеру
 */

const burgerStagesOrdersByStatuses: Array<{
  id: number;
  statuses: Array<applicationStatusType>;
}> = [
  {
    id: 0,
    statuses: [
      "new",
      "passport_filling",
      "inn_check_process",
      "survey_filling",
      "signing_applying_agreement_filling",
    ],
  },
  {
    id: 1,
    statuses: [
      "sent_process",
      "scoring_process",
      "underwriter_scoring_process",
    ],
  },
  {
    id: 2,
    statuses: ["video_call"],
  },
  {
    id: 3,
    statuses: [
      "car_document_filling",
      "car_photo_filling",
      "passport_photo_filling",
      "need_document_correction_filling",
    ],
  },
  {
    id: 4,
    statuses: [
      "need_replace_photo_filling",
      "document_quality_underwriter_scoring_process",
      "document_scoring_process",
      "document_underwriter_scoring_process",
      "super_underwriter_scoring_process",
    ],
  },
  {
    id: 5,
    statuses: ["signing_loan_contract_filling", "signed_issuing_sbp_error"],
  },
  {
    id: 6,
    statuses: [
      "generate_loan_contract_process",
      "signed_issuing_process",
      "signed_check_documents_process",
    ],
  },
  {
    id: 7,
    statuses: ["issued_final"],
  },
];

const burgerStagesOrderByUrls = [
  // анкета (стрелка)
  [
    // routes.auth.vehicle_valuation,
    // routes.auth.phone,
    // routes.auth.code,
    // routes.auth.restore_password,
    routes.application.passport_and_inn,
    routes.application.statuses.data_verification,
    routes.application.sbp_bank_filling,
    routes.application.code.loan,
  ],
  // анкета (прелоадер)
  [routes.application.statuses.sent_and_scoring_process],
  // фото документов (стрелка)
  [routes.application.documents, routes.application.passport_and_inn],
  // фото документов (прелоадер)
  [
    routes.application.statuses.scoring_and_loan_disbursement,
    routes.application.statuses.scoring_and_loan_disbursement,
  ],
  // выдача (стрелка)
  [routes.application.code.contract],
  // выдача (прелоадер)
  [
    routes.application.statuses.signed_issuing_process,
    routes.application.statuses.payment_system_response,
  ],

  // фото документов
  [
    routes.application.documents,
    routes.application.statuses.recognize_documents,
    // routes.application.passport_and_inn,
  ],
  // фото автомобиля
  [routes.application.car_photo],
  // принятие решения
  [
    routes.application.loan_decision,
    "/application/code/contract",
    routes.application.statuses.signing_process,
    routes.application.statuses.signed_issuing_process,
    routes.application.statuses.sbp_bank_filling,
    routes.application.statuses.sbp_check_error,
    routes.application.statuses.sbp_check_error_connected,
    routes.application.statuses.sbp_check_error_not_sbp_bank,
    routes.application.statuses.sbp_check_error_name_differs,
    routes.application.statuses.issue_method_filling,
    // routes.application.statuses.sent_and_scoring_process,
    routes.application.code.loan,
    routes.application.statuses.account_confirmation,
    routes.application.statuses.account_check,
    // issued_final стоит закинуть в map, чтобы галочка была
    routes.application.statuses.issued_final,
  ],
  // // создание  заявки
  // [routes.application.new_loan, routes.application.statuses.checking_car],
  //
  // // Фото документов
  // [routes.application.documents],
  //
  // // Отправка фото
  // [routes.application.statuses.recognize_documents],
  //
  // // Проверка данных
  // [routes.application.passport_and_inn],
  //
  // // Фото автомобиля
  // [routes.application.car_photo],
  // //Отправка фото
  // [
  //   routes.application.statuses.issue_method_filling,
  //   routes.application.statuses.account_check,
  // ],
  //
  // //Привязка карты
  // [
  //   routes.application.statuses.sbp_define_process,
  //   routes.application.statuses.account_confirmation,
  // ],
  //
  // //Отправка заявки
  // [routes.application.code.loan],
  //
  // // //Ввод карты
  // // [routes.application.loan_processing],
  //
  // // Решение по займу
  // [
  //   routes.application.statuses.sent_and_scoring_process,
  //   routes.application.loan_decision,
  //   "/application/code/contract",
  // ],
  //
  // // Оформление займа
  // [
  //   // routes.application.loan_processing,
  //   routes.application.statuses.signing_process,
  //   routes.application.statuses.signed_issuing_process,
  //   // issued_final стоит закинуть в map, чтобы галочка была
  //   routes.application.statuses.issued_final,
  // ],
];

const statusesLabels = [
  // "Создание заявки",
  // "Фото документов",
  // // changed label
  // "Отправка фото",
  // "Проверка данных",
  // "Фото автомобиля",
  // "Отправка фото",
  // "Привязка карты",
  // // "Проверка счета",
  // // new line
  // "Отправка заявки",
  // "Решение по займу",
  // "Оформление займа",
  // "Проверка автомобиля",
  // "Фото документов",
  // "Фото автомобиля",
  // "Принятие решения",
  "Анкета",
  "Видеозвонок",
  "Фото документов",
  "Выдача",
];

// API.main.application.getApplication

export const BurgerMenu = createFC(
  ({ isBurgerOpen, setIsBurgerOpen }: propsType) => {
    const [indexOfCurrentApplicationType, setIndexOfCurrentApplicationType] =
      useState(0);

    const dispatch = useDispatch();
    const history = useHistory();
    const { pathname } = useLocation();

    const pathnameForMetricsLeave = [
      "/application/fio_and_grz",
      "/application/data_verification",
      "/application/passport_and_inn",
      "/application/sbp_bank_filling",
      "/application/code/loan",
    ];

    const currentApplicationType = useSelector(
      applicationReducerSelectors.getApplicationType
    );

    const phoneNumber = useSelector(userReducerSelectors.getPhone);

    const isRefused =
      currentApplicationType === "user_refused_final" ||
      currentApplicationType === "refusal_final" ||
      currentApplicationType === "error_final";

    const resolvedIndex = burgerStagesOrderByUrls.findIndex((el) => {
      return el.includes(pathname);
    });

    const resolvedId = burgerStagesOrdersByStatuses.find((el) =>
      el.statuses.find((el) => el === currentApplicationType)
    )?.id;

    useEffect(() => {
      // страницу user_refused_final добавить сюда. Это когда чел ушел и заявка сама падает в статус.
      if (
        [
          routes.application.statuses.refusal_final_bad_car,
          routes.application.statuses.error_final,
        ].includes(pathname)
      )
        return;
      if (pathname === routes.application.statuses.issued_final) {
        setIndexOfCurrentApplicationType(3);
        return;
      }
      setIndexOfCurrentApplicationType(resolvedIndex);
    }, [pathname]);

    // console.log(currentApplicationType);

    const closeBurger = () => {
      document.querySelector("html")!.style.overflow = "auto";
      setIsBurgerOpen(false);
    };

    return (
      <>
        <motion.div
          variants={backgroundAnimation}
          initial={"close"}
          animate={isBurgerOpen ? "open" : "close"}
          className={styles.background}
          onClick={closeBurger}
        ></motion.div>

        <motion.div
          variants={burgerAnimation}
          initial={"close"}
          animate={isBurgerOpen ? "open" : "close"}
          className={styles.burger}
        >
          <div className={styles.circle}></div>
          <div className={styles.header}>
            <Icons.logos.CreddyViolet className={styles.creddy_logo} />
            <div>
              {/*TODO svg*/}
              {/*<Icons.ui.CrossBlackInCircle*/}
              {/*  className={styles.cross_icon}*/}
              {/*  onClick={closeBurger}*/}
              {/*/>*/}
            </div>
          </div>
          <div>
            <div className={styles.phone}>{phoneNumber}</div>
            <div
              className={styles.logout}
              onClick={() => {
                pathnameForMetricsLeave?.includes(pathname) &&
                  sendMetrics("common-client_leave-LK_common-app");
                logout.default(dispatch);
              }}
            >
              <div className={styles.logout_icon}>
                <Icons.ui.Logout />
              </div>
              <div className={styles.logout_label}>Выйти</div>
            </div>
            <div className={styles.divider} />
          </div>
          <div className={styles.stages}>
            <div className={styles.stage}>
              <div className={styles.icon}>
                {resolvedId === 0 || resolvedId === undefined ? (
                  <Icons.ui.ArrowGreenInCircle />
                ) : resolvedId === 1 ? (
                  <Icons.ui.Preloader />
                ) : resolvedId && resolvedId > 1 ? (
                  <Icons.ui.CheckmarkGreenBg />
                ) : (
                  <Icons.ui.EmptyCircle />
                )}
                <div
                  className={cn(styles.connect_line, {
                    [styles.connect_line_short]: true,
                    [styles.connect_line_green]: resolvedId && resolvedId > 1,
                  })}
                />
              </div>
              <div className={styles.stage_label}>Анкета</div>
            </div>
            <div className={styles.stage}>
              <div className={styles.icon}>
                {resolvedId === 2 ? (
                  <Icons.ui.ArrowGreenInCircle />
                ) : resolvedId && resolvedId > 2 ? (
                  <Icons.ui.CheckmarkGreenBg />
                ) : (
                  <Icons.ui.EmptyCircle />
                )}
                <div
                  className={cn(styles.connect_line, {
                    [styles.connect_line_short]: resolvedId && resolvedId > 1,
                    [styles.connect_line_green]: resolvedId && resolvedId > 2,
                  })}
                />
              </div>
              <div className={styles.stage_label}>Звонок</div>
            </div>
            <div className={styles.stage}>
              <div className={styles.icon}>
                {resolvedId === 3 ? (
                  <Icons.ui.ArrowGreenInCircle />
                ) : resolvedId === 4 ? (
                  <Icons.ui.Preloader />
                ) : resolvedId && resolvedId > 4 ? (
                  <Icons.ui.CheckmarkGreenBg />
                ) : (
                  <Icons.ui.EmptyCircle />
                )}
                <div
                  className={cn(styles.connect_line, {
                    [styles.connect_line_short]: resolvedId && resolvedId > 2,
                    [styles.connect_line_green]: resolvedId && resolvedId > 4,
                    [styles.connect_line_refused]: isRefused,
                  })}
                />
              </div>
              <div className={styles.stage_label}>Фото документов</div>
            </div>
            <div className={styles.stage}>
              <div className={styles.icon}>
                {isRefused ? (
                  <Icons.ui.CrossRedInGrayCircle />
                ) : resolvedId === 5 ? (
                  <Icons.ui.ArrowGreenInCircle />
                ) : resolvedId === 6 ? (
                  <Icons.ui.Preloader />
                ) : resolvedId === 7 ? (
                  <Icons.ui.CheckmarkGreenBg />
                ) : (
                  <Icons.ui.EmptyCircle />
                )}
              </div>
              <div className={styles.stage_label}>Выдача</div>
            </div>
            {/*{statusesLabels.map((label, index) => {*/}
            {/*  return (*/}
            {/*    <div className={styles.stage}>*/}
            {/*      {index < indexOfCurrentApplicationType && (*/}
            {/*        <Icons.ui.Checked />*/}
            {/*      )}*/}
            {/*      {index === indexOfCurrentApplicationType && (*/}
            {/*        <Icons.ui.ArrowGreenInCircle*/}
            {/*          className={styles.checkmark_with_loader}*/}
            {/*        />*/}
            {/*      )}*/}
            {/*      {index > indexOfCurrentApplicationType && (*/}
            {/*        <div className={styles.empty_circle}>*/}
            {/*          <Icons.ui.EmptyCircle />*/}
            {/*        </div>*/}
            {/*      )}*/}
            {/*      <div*/}
            {/*        className={cn(styles.stage_label, {*/}
            {/*          [styles.stage_label__active]:*/}
            {/*            index === indexOfCurrentApplicationType,*/}
            {/*        })}*/}
            {/*      >*/}
            {/*        {label}*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  );*/}
            {/*})}*/}

            {/*<div style={{ marginTop: "150px" }}>*/}
            {/*  <div style={{ marginBottom: "8px"}}>&copy; Creddy, 2022</div>*/}
            {/*  <div style={{ marginBottom: "8px"}}>Остались вопросы?</div>*/}
            {/*  <div>Звоните: <span style={{ fontWeight: 600 }}>8 800 600 1776</span></div>*/}
            {/*  <div className={styles.circle}></div>*/}
            {/*</div>*/}

            <div className={styles.circle_green}></div>
            <div className={styles.build_version}>
              Версия сборки {packageJson.version}
            </div>
          </div>

          {/*<div className={styles.footer}>*/}
          {/*  <div className={styles.footer_links}>*/}
          {/*    <a*/}
          {/*      href={`https://wa.me/${constantStrings.whatsappNumberForSupport}`}*/}
          {/*      target={"_blank"}*/}
          {/*    >*/}
          {/*      <Icons.logos.Whatsapp />*/}
          {/*    </a>*/}
          {/*    <a*/}
          {/*      href={constantStrings.telegramLinkForSupport}*/}
          {/*      target={"_blank"}*/}
          {/*    >*/}
          {/*      <Icons.logos.Telegram />*/}
          {/*    </a>*/}
          {/*    <a href={`tel:${constantStrings.phoneNumberForSupport}`}>*/}
          {/*      <Icons.logos.Phone />*/}
          {/*    </a>*/}
          {/*  </div>*/}
          {/*  <div className={styles.footer_title}>Связаться с техподдержкой</div>*/}
          {/*</div>*/}
        </motion.div>
      </>
    );
  }
);
