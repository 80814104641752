import { useEffect, useRef } from "react";
import get from "lodash/get";
import { TextInputPropsType } from "../types";
import { Icons } from "../../../../assets/icons";
import { ConnectedForm } from "../../ConnectedForm";
import { InputBase } from "../InputBase";
import { useToggle } from "../../../../hooks/logical/useToggle";

export const InputEditable = (props: Omit<TextInputPropsType, "control">) => {
  const [isEditing, toggleEditing] = useToggle();
  const ref = useRef<any>();

  useEffect(() => {
    if (isEditing) {
      ref.current.focus();
    }
  }, [isEditing]);

  return (
    <ConnectedForm>
      {({ control, formState: { errors } }) => (
        <InputBase
          {...props}
          inputElementRef={ref}
          disabled={!isEditing}
          support={
            isEditing ? (
              <Icons.ui.PencilBlack onClick={toggleEditing} />
            ) : (
              <Icons.ui.PencilBlack onClick={toggleEditing} />
            )
          }
          error={props.error || get(errors as any, `${props.name}.message`)}
          control={control}
        />
      )}
    </ConnectedForm>
  );
};
