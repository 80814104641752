import { useSelector } from "react-redux";
import {
  applicationReducer,
  applicationReducerSelectors,
} from "../../store/reducers/application";
import { useQuery } from "react-query";
import { API } from "../../api";
import { useEffect } from "react";
import { UseFormSetValue } from "react-hook-form";
import { passportAndInnFormType } from "../../components/pages/Application/pages/PassportAndInn/types";
import {
  passportMainFieldsType,
  passportRegistrationFieldsType,
} from "../../types/documents/types";
import { dateMethods } from "../../helpers/date";
import { applicationType } from "../../types/application/types";
import { routes } from "../../routes";

export const useGetInfoFromDocuments = (
  setValue: UseFormSetValue<passportAndInnFormType>
) => {
  const applicationId = useSelector(
    applicationReducerSelectors.getApplicationId
  );

  const optionalPassportMainInfo = useQuery(
    ["OptionalPassportMain", applicationId],
    () =>
      API.main.documents.getInfoFromDocument({
        applicationId,
        photo_type: "optional_passport_main",
      }),
    {
      onError: () => {
        console.log();
      },
      retry: false,
      enabled: applicationId !== null,
    }
  );

  const optionalPassportRegistrationInfo = useQuery(
    ["OptionalPassportRegistration", applicationId],
    () =>
      API.main.documents.getInfoFromDocument({
        applicationId,
        photo_type: "optional_passport_registration",
      }),
    {
      onError: () => {
        console.log();
      },
      retry: false,
      enabled: applicationId !== null,
    }
  );

  useEffect(() => {
    if (
      optionalPassportMainInfo?.data &&
      optionalPassportRegistrationInfo?.data
    ) {
      const passportMainFields: passportMainFieldsType =
        optionalPassportMainInfo?.data?.data?.data?.fields;
      const passportRegistrationFields: passportRegistrationFieldsType =
        optionalPassportRegistrationInfo?.data?.data?.data?.fields;

      // let firstName = String(
      //   passportMainFields?.first_name?.value
      // ).toLowerCase();
      // let last_name = String(passportMainFields?.surname?.value).toLowerCase();
      // let middle_name = String(
      //   passportMainFields?.other_names?.value
      // ).toLowerCase();
      // setValue(
      //   "passport.first_name",
      //   firstName?.charAt(0).toUpperCase() + firstName?.slice(1)
      // );
      // setValue(
      //   "passport.last_name",
      //   last_name?.charAt(0).toUpperCase() + last_name?.slice(1)
      // );
      // setValue(
      //   "passport.middle_name",
      //   middle_name?.charAt(0).toUpperCase() + middle_name?.slice(1)
      // );

      // setValue("passport.gender", passportMainFields?.sex?.value !== "МУЖ");

      setValue(
        "passport.series",
        passportMainFields?.series_and_number?.value?.slice(0, 4) || null
      );
      setValue(
        "passport.number",
        passportMainFields?.series_and_number?.value?.slice(5) || null
      );

      // const inputValueBirthDate =
      //   passportMainFields?.date_of_birth?.value || null;
      const inputValueIssueDate =
        passportMainFields?.date_of_issue?.value || null;

      const regex = /^(\d{2})\.(\d{2})\.(\d{4})$/;

      // if (inputValueBirthDate && regex.test(inputValueBirthDate)) {
      //   setValue(
      //     "passport.birth_day",
      //     dateMethods.format2(inputValueBirthDate)
      //   );
      // }

      if (inputValueIssueDate && regex.test(inputValueIssueDate)) {
        setValue(
          "passport.issue_date",
          dateMethods.format2(inputValueIssueDate)
        );
      }

      const inputValueDivisionCode =
        passportMainFields?.subdivision_code?.value || null;
      const regexDivisionCode = /^\d{3}-\d{3}$/;
      if (
        inputValueDivisionCode &&
        regexDivisionCode.test(inputValueDivisionCode)
      ) {
        setValue(
          "passport.division_code",
          passportMainFields?.subdivision_code?.value
        );
      }

      setValue(
        "passport.issued_by",
        passportMainFields?.issuing_authority?.value || null
      );

      setValue(
        "passport.birth_place",
        passportMainFields?.place_of_birth?.value
      );

      // setValue("inn", passportMainFields?.inn?.value);
    }
  }, [optionalPassportMainInfo?.data, optionalPassportRegistrationInfo?.data]);

  return {
    isLoading:
      optionalPassportMainInfo?.isLoading &&
      optionalPassportRegistrationInfo?.isLoading,
    //  * макс длина инн должна быть 12
    firstName:
      optionalPassportMainInfo?.data?.data?.data?.fields?.first_name?.value,
    lastName:
      optionalPassportMainInfo?.data?.data?.data?.fields?.surname?.value,
    middleName:
      optionalPassportMainInfo?.data?.data?.data?.fields?.other_names?.value,
    inn: optionalPassportMainInfo?.data?.data?.data?.fields?.inn?.value,
    registration_address:
      optionalPassportRegistrationInfo?.data?.data?.data?.fields?.address
        ?.value,
  };
};
