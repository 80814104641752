import { validateInn } from "helpers/validation/validateInn";
import * as yup from "yup";
import { validationsMessages } from "../../../../../helpers/validation/validationsMessages";
import { dateMethods } from "../../../../../helpers/date";

let minData: any = dateMethods.dateMinus60Years();
let maxData: any = dateMethods.dateMinus18Years();

// maxData = Number(maxData);
// minData = Number(minData) - 129600000;

// console.log("Number(maxData);", Number(maxData));

export const emailRegex =
  /^[\s\S]*\S[\s\S]*[-a-z0-9!#$%&'*+/=?^_`{|}~]+(\.[-a-z0-9!#$%&'*+/=?^_`{|}~]+)*@([a-z0-9]([-a-z0-9]{0,61}[a-z0-9])?\.)*(aero|arpa|asia|biz|cat|com|coop|edu|gov|info|int|ru|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|[a-z][a-z])\s*$/i;

export const fioAndGrzSchema = yup.object().shape({
  passport: yup.object().shape({
    last_name: yup
      .string()
      .required(validationsMessages.requiredField)
      .matches(
        /^(?:\s*[а-яёА-ЯЁa-zA-Z\d]+(?:[ -][а-яёА-ЯЁa-zA-Z\d]*)*\s*)$/i,
        validationsMessages.onlyLetters
      ),
    first_name: yup
      .string()
      .required(validationsMessages.requiredField)
      .matches(
        /^(?:\s*[а-яёА-ЯЁa-zA-Z\d]+(?:[ -][а-яёА-ЯЁa-zA-Z\d]*)*\s*)$/i,
        validationsMessages.onlyLetters
      ),
    // middle_name: yup
    //   .string()
    //   .nullable()
    //   .matches(
    //     /^(?:\s*[а-яёА-ЯЁa-zA-Z\d]+(?:[ -][а-яёА-ЯЁa-zA-Z\d]*)*\s*)$/i,
    //     validationsMessages.onlyLetters
    //   ),
    birth_day: yup
      .date()
      .required(validationsMessages.requiredField)
      // .max(maxData, validationsMessages.maxDate)
      .min(minData, validationsMessages.minDate),
  }),
  email: yup
    .string()
    .required(validationsMessages.requiredField)
    .matches(emailRegex, validationsMessages.invalidEmail),
});
