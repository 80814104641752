import React, { AllHTMLAttributes, FC } from "react";
import cn from "classnames";
import styles from "./index.module.css";
import { Divider } from "../../../Documents/components/DocumentsBlockHeader";
import moment from "moment/moment";
import { dateMethods } from "../../../../../../../helpers/date";
import { stringHelpers } from "../../../../../../../helpers/string";

type propsType = {
  allSchedule: any;
  car: any;
  currentLoan: any;
} & AllHTMLAttributes<HTMLDivElement>;

export const LoanInfoDesktop: FC<propsType> = ({
  allSchedule,
  currentLoan,
  car,
  className,
}) => {
  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.title}>Общая информация о займе</div>

      <div className={styles.grid}>
        <div className={styles.data_pair}>
          Название услуги:{" "}
          <span className={styles.bold}>Займ под залог авто</span>
        </div>
        <div className={styles.data_pair}>
          Номер договора:{" "}
          <span className={styles.bold}>{currentLoan?.loan_number}</span>
        </div>
        <div className={styles.data_pair}>
          Валюта: <span className={styles.bold}>Рубль России, &#8381;</span>
        </div>
        <div className={styles.data_pair}>
          Процентная ставка по кредиту:{" "}
          <span className={styles.bold}>{`${(
            currentLoan?.loan_data?.percent_rate / 12
          ).toFixed(1)} % (в месяц)`}</span>
        </div>
      </div>

      <Divider style={{ marginBottom: "24px" }} />

      <div className={styles.grid}>
        <div className={styles.data_pair}>
          Дата выдачи кредита:{" "}
          <span className={styles.bold}>
            {moment(currentLoan?.issued_date).format("DD.MM.YYYY")}
          </span>
        </div>
        <div className={styles.data_pair}>
          Дата окончания действия кредита:{" "}
          <span className={styles.bold}>
            {dateMethods.format2(
              allSchedule?.schedule?.[allSchedule?.schedule.length - 1]?.date,
              true
            )}
          </span>
        </div>
        <div className={styles.data_pair}>
          Срок договора:{" "}
          <span className={styles.bold}>{`${
            currentLoan?.loan_data?.loan_term
          } ${
            currentLoan?.loan_data?.loan_term === "24" ? "месяца" : "месяцев"
          }`}</span>
        </div>
      </div>

      <Divider style={{ marginBottom: "24px" }} />

      <div className={styles.grid}>
        <div className={styles.data_pair}>
          Текущий остаток:{" "}
          <span className={styles.bold}>{`${stringHelpers.transformMoneyValue(
            currentLoan?.current_situation?.current_loan_body
          )} ₽`}</span>
        </div>
        <div className={styles.data_pair}>
          Дата очередного платежа:{" "}
          <span className={styles.bold}>
            {dateMethods.format2(currentLoan?.next_payment.date ?? null, true)}
          </span>
        </div>
        <div className={styles.data_pair}>
          Сумма очередного платежа:{" "}
          <span className={styles.bold}>{`${stringHelpers.transformMoneyValue(
            currentLoan?.next_payment?.amount
          )} ₽`}</span>
        </div>
        <div className={styles.data_pair}>
          Штрафы:{" "}
          <span className={styles.bold}>
            {currentLoan?.current_situation?.loan_body_penalties
              ? `${stringHelpers.transformMoneyValue(
                  currentLoan?.current_situation?.loan_body_penalties
                )} ₽`
              : "0 ₽"}
          </span>
        </div>
        <div className={styles.data_pair}>
          Начислено пени:{" "}
          <span className={styles.bold}>
            {currentLoan?.current_situation?.loan_body_penalties
              ? `${stringHelpers.transformMoneyValue(
                  currentLoan?.current_situation.percent_penalties
                )} ₽`
              : "0 ₽"}
          </span>
        </div>
      </div>
    </div>
  );
};
