import React, { ReactFragment } from "react";
import styles from "./index.module.css";
import { TextInputPropsType } from "components/ui/Inputs/types";
import cn from "classnames";
import { ConnectedForm } from "../ConnectedForm";
import { Icons } from "../../../assets/icons";

type checkBoxPropsType<T> = {
  blockValue?: boolean;
  iconPosition?: "left" | "right";
} & Omit<TextInputPropsType, "control">;

export function Checkbox<T>({
  name,
  className,
  iconPosition = "left",
  blockValue,
  ...jsxAttr
}: checkBoxPropsType<T>) {
  return (
    <ConnectedForm>
      {({ control }) => {
        const inputProps = control.register(name);


        return (
          <div className={cn(styles.container, className)}>
            <div className={styles.Input__wrapper}>
              <input
                type="checkbox"
                className={styles.input}
                id={jsxAttr.value || name}
                {...jsxAttr}
                value={blockValue ? undefined : jsxAttr.value}
                {...control.register(name)}
                onChange={(event) => {
                  inputProps.onChange(event).finally();
                  jsxAttr.onChange && jsxAttr.onChange(event);
                }}
              />
              <label htmlFor={jsxAttr.value || name} className={styles.label}>
                {jsxAttr.label && (
                  <div className={styles.label_title}>{jsxAttr.label}</div>
                )}
                <div
                  className={cn(styles.icon_checked, {
                    [styles.icon_checked__left]: iconPosition === "left",
                    [styles.disabled]: jsxAttr.disabled,
                  })}
                >
                  <Icons.ui.Checkbox_checked />
                </div>
                <div
                  className={cn(styles.icon_unchecked, {
                    [styles.icon_unchecked__left]: iconPosition === "left",
                    [styles.disabled]: jsxAttr.disabled,
                  })}
                >
                  <Icons.ui.Checkbox_unchecked />
                </div>
              </label>
            </div>
          </div>
        );
      }}
    </ConnectedForm>
  );
}

export function CheckBoxWithTitle<T>({
  text,
  ...props
}: checkBoxPropsType<T> & {
  text: string;
}) {
  return (
    <label className={cn(styles.with_title, props.className)}>
      {text && <span className={styles.with_title__text}>{text}</span>}
      <Checkbox {...props} className={undefined} />
    </label>
  );
}

export function CheckboxUncontrolled<T>({
  name,
  className,
  iconPosition = "left",
  blockValue,
  text,
  ...jsxAttr
}: checkBoxPropsType<T> & {
  text: string | ReactFragment;
} ) {
  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.Input__wrapper}>
        <input
          type="checkbox"
          className={styles.input}
          id={jsxAttr.value || name}
          value={blockValue ? undefined : jsxAttr.value}
          onChange={(event) => {
            jsxAttr.onChange && jsxAttr.onChange(event);
          }}
          checked={jsxAttr?.checked}
        />
        <label htmlFor={jsxAttr.value || name} className={styles.label}>
          {text && (
            <div className={styles.label_title}>{text}</div>
          )}
          <div
            className={cn(styles.icon_checked, {
              [styles.icon_checked__left]: iconPosition === "left",
            })}
          >
            <Icons.ui.Checkbox_checked />
          </div>
          <div
            className={cn(styles.icon_unchecked, {
              [styles.icon_unchecked__left]: iconPosition === "left",
            })}
          >
            <Icons.ui.Checkbox_unchecked />
          </div>
        </label>
      </div>
    </div>
  );
}
