import React, { FC, useEffect, useState } from "react";
import { useForm, useFormContext, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styles from "./index.module.css";
import { SmartForm } from "../../../../hocs/SmartForm";
import { InputNumber } from "../../../../ui/Inputs/InputNumber";
import { onPastePhoneNumberHandler } from "../../../../../helpers/string";
import { Button } from "../../../../ui/Button";
import { authSagaActions } from "../../../../../sagas/auth/actions";
import { processLoading } from "../../../../../store/auxiliary/loadingReducer";
import { VinAndLicensePlate } from "../../../../ui/VinAndLicensePlate";
import { useGetCar } from "../../../../../hooks/api/useGetCar";
import { API } from "../../../../../api";
import axios from "axios";
import { routes } from "../../../../../routes";
import { useHistory } from "react-router-dom";
import { lsController } from "../../../../../store/auxiliary/localStorage";
import {
  applicationReducer,
  applicationReducerSelectors,
} from "../../../../../store/reducers/application";
import { userReducer } from "../../../../../store/reducers/user";
import {
  carBodyType,
  carType,
} from "../../../../../store/reducers/application/types";
import { userReducerSelectors } from "../../../../../store/reducers/user/selectors";
import { useFetchCar } from "../../../../../hooks/logical/useFetchCar";
import { LoadingPage } from "../../../Application/pages/LoadingPage";
import { LoaderPage } from "../../../../ui/LoaderPage";
import { FillingAssistance } from "../../../../ui/FillingAssistance";

type formType = {
  reg_number: string;
  vin_number: string;
  vin_or_license_plate_selected: boolean | undefined;
};

type propsType = {
  width?: number;
};

export const VehicleNotFound: FC<propsType> = (props) => {
  const dispatch = useDispatch();

  const [isLoadingFetchCar, setIsLoadingFetchCar] = useState(false);

  const form = useForm<formType>({
    reValidateMode: "onSubmit",
    // resolver: yupResolver(scheme),
  });

  const phoneNumber = useSelector(userReducerSelectors.getPhone);

  const history = useHistory();

  const reg_number = useWatch({
    control: form.control,
    name: "reg_number",
  });

  const vin = useWatch({
    control: form.control,
    name: "vin_number",
  });

  const isChecked = useWatch({
    control: form.control,
    name: "vin_or_license_plate_selected",
  });

  React.useEffect(() => {
    lsController.clearGRZ();
    lsController.clearVIN();
  }, []);

  const fetchCar = useFetchCar(
    // reg_number,
    // vin,
    phoneNumber,
    history,
    setIsLoadingFetchCar
  );

  const submit = (data: formType) => {
    if (!isChecked) {
      if (reg_number?.length >= 8) {
        setIsLoadingFetchCar(true);
        lsController.set("grz", reg_number);
        dispatch(
          applicationReducer.actions.setCar({
            car: { vin, reg_number },
          })
        );
        fetchCar(reg_number);
      } else {
        form.setError("reg_number", {
          type: "custom",
          message: "3аполните поле",
        });
      }
    }
    if (isChecked) {
      if (vin?.length === 17) {
        setIsLoadingFetchCar(true);
        lsController.set("vin", vin);
        dispatch(
          applicationReducer.actions.setCar({
            car: { vin, reg_number },
          })
        );
        fetchCar(vin);
      } else {
        form.setError("vin_number", {
          type: "custom",
          message: "3аполните поле",
        });
      }
    }
  };

  return (
    <div className={styles.container}>
      {isLoadingFetchCar ? (
        <LoaderPage />
      ) : (
        <SmartForm form={form} submit={submit}>
          <div className={styles.title}>Внимание!</div>
          <div className={styles.subtitle}>
            Введен неправильный номер, не удалось определить автомобиль.
            Пожалуйста, введите верные данные
          </div>
          <VinAndLicensePlate
            // @ts-ignore
            title
            setError={form.setError}
            className={styles.vin_and_license_plate}
          />
          <Button
            className={styles.button}
            disabled={
              (!isChecked && !reg_number) ||
              (isChecked && !vin) ||
              (!isChecked && reg_number.length < 8) ||
              (isChecked && vin.length < 17)
            }
          >
            Продолжить
          </Button>
          <div className={styles.footer}>{/*<FillingAssistance />*/}</div>
        </SmartForm>
      )}
    </div>
  );
};
