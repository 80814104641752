import { PayloadAction } from "@reduxjs/toolkit";
import { getTokenType } from "../../../api/auth/types";
import { processStateController } from "../../util/helpers/processStateController";
import { call, put, select } from "redux-saga/effects";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import { sagaApiType } from "../../util/types";
import { API } from "../../../api";
import { userReducer } from "../../../store/reducers/user";
import { routingAction } from "../../util/helpers/routingSaga";
import { errorsReducer } from "../../../store/auxiliary/errorsReducer";

export function* getTokenSaga({
  payload,
  type: actionType,
}: PayloadAction<getTokenType>) {
  const process = processStateController(actionType);
  console.log("getTokenSaga called");
  try {
    yield process.start();

    //TODO лишний запрос и перезапись токена, мы его получаем из тела запроса createPassword

    // const userTokenResponse: sagaApiType = yield call(API.auth.token, payload);
    //
    // const { token } = userTokenResponse.data.data;
    //
    // yield put(
    //   userReducer.actions.setToken({
    //     token,
    //   })
    // );

    const response: sagaApiType = yield call(API.auth.getUserTokenized);
    const user = response.data.data;

    if (["underwriter", "admin"].includes(user?.user_role)) {
      yield put(
        errorsReducer.actions.setFieldsErrors({
          password: "Неверная роль пользователя",
        })
      );
      yield process.stop();
      return;
    }

    yield put(
      userReducer.actions.setUserRole({
        user_role: user.user_role,
      })
    );

    yield put(routingAction());
  } catch (e: any) {
    if (
      e?.response?.status &&
      (e.response?.status == 401 || e.response?.status == 400)
    ) {
      yield put(
        errorsReducer.actions.setFieldsErrors({
          password: "Неверный пароль",
        })
      );
      yield process.stop();
    } else {
      yield call(errorHandlerSaga, {
        response: e,
      });
      yield process.stop();
    }
  }
}
