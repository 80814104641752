import { useEffect } from "react";
import { sendMetrics } from "../index";

const useTrackTabClose = (metricName: string, variant?: string) => {
  useEffect(() => {
    if (variant === "contract") {
      return;
    }
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      sendMetrics(metricName);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [metricName]);
};

export default useTrackTabClose;
