import React, { AllHTMLAttributes, FC, ReactNode, RefObject } from "react";
import styles from "./style.module.css";
import cn from "classnames";
import ReactInputMask from "react-input-mask";
import { RefCallBack } from "react-hook-form";

type propsType = {
  support?: ReactNode;
  error?: string;
  customRef?: RefCallBack;
  customOnBlur?: (e: any) => void;
  isOpen?: boolean;
  variant?: "default" | "large";
} & AllHTMLAttributes<HTMLInputElement>;
export const CalendarInput: FC<propsType> = ({
  className,
  support,
  customRef,
  isOpen,
  variant = "default",
  customOnBlur,
  ...props
}) => {
  return (
    <div
      className={cn({
        [styles.container]: true,
        [`${className}`]: className,
        [styles.error_state]: Boolean(props.error),
      })}
    >
      <ReactInputMask
        //@ts-ignore
        maskChar={null}
        mask="99.99.9999"
        inputRef={customRef}
        className={cn(styles.Input, {
          [styles.openCalendar]: isOpen,
          [styles.Input_large]: variant === "large",
        })}
        {...props}
        onBlur={(e: any) => {
          customOnBlur && customOnBlur(e);
          props.onBlur && props.onBlur(e);
        }}
      />
      <label className={styles.label}>{props.placeholder}</label>
      {support && (
        <span
          className={cn({
            [styles.Input__support]: true,
          })}
        >
          {support}
        </span>
      )}
      {props.error && <div className={styles.error}>! {props.error}</div>}
    </div>
  );
};
