import styles from "./index.module.css";
import React, { FC, useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import cn from "classnames";
import { VideoCallTimeFormType } from "../../pages/Application/pages/Statuses/SentAndScoringProcess";
import { SwitcherTriple } from "../SwitcherTriple";
import { TimePicker } from "../TimePicker";
import { useClock } from "../../../hooks/logical/useClock";

type propsType = {
  form: UseFormReturn<VideoCallTimeFormType> & any;
};

export const Clock: FC<propsType> = ({ form }) => {
  const clockRef = React.useRef(null);
  const ref = React.createRef();
  let targetElement = null;

  const {
    currentHours,
    selectedDay,
    hoursArr,
    minutesArr,
    defaultTime,
    todayIsTooLate
  } = useClock(form);

  useEffect(() => {
    targetElement = ref.current;
  }, [currentHours, selectedDay]);

  return (
    <div className={styles.clock_block}>
      <div
        className={styles.clock}
        ref={clockRef}
        onTouchStart={(e) => {
          // e.preventDefault();
          // disableBodyScroll(clockRef.current!);
          document.body.style.setProperty("height", "-webkit-fill-available");
          // document.body.style.setProperty("height", "-webkit-fill-available");
          // document.body.style.setProperty("right", "0px");
          // document.body.style.setProperty("position", "fixed");

        }}
        onTouchEnd={() => {
          // enableBodyScroll(clockRef.current!);
        }}
      >
        <div style={{fontSize: 14}}>Час.</div>
        <div className={styles.clock_title}>
          <div className={styles.clock_digits}>
            <TimePicker
              name={"hours"}
              values={hoursArr}
              defaultValue={defaultTime.hours.toString()}
            />
          </div>
        </div>
        <div className={styles.dots}>
          <div className={cn(styles.clock_digits, styles.dots_not_main)}>:</div>
          <div className={styles.clock_digits}>:</div>
          <div className={cn(styles.clock_digits, styles.dots_not_main)}>:</div>
        </div>
        <div className={styles.clock_title}>
          <div className={styles.clock_digits}>
            <TimePicker
              name={"minutes"}
              values={minutesArr}
              defaultValue={defaultTime.minutes.toString()}
            />
          </div>
        </div>
        <div style={{fontSize: 14}}>Мин.</div>
      </div>
      <div className={styles.switcher}>
        <SwitcherTriple
          name={"day"}
          className={styles.switch}
          values={["today", "tomorrow", "after-tomorrow"]}
          innerLabels={["Сегодня", "Завтра", "Послезавтра"]}
          switchVariant={"huge_gender"}
          variant={"large"}
          disabled={todayIsTooLate}
        />
      </div>
    </div>
  );
};
