import { call, put, select, takeEvery } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { errorMapper } from "api/methods/errorMapper";
import { sagaActions } from "../sagaActions";
import { setProcessLoading } from "../../../store/auxiliary/loadingReducer";
import { errorsReducer } from "../../../store/auxiliary/errorsReducer";
import { logout } from "../../../store/reducers/user";
import { getFirstErrorFromResponse } from "../../../api/methods/getFirstErrorFromResponse";
import { uiReducer } from "../../../store/reducers/ui";
import { StateType } from "../../../store";
import { authSagaActions } from "../../auth/actions";

const getTextFromAxiosErrorMessage = (message: string) => {
  if (message?.includes("Network")) {
    return "Ошибка сети. Проверьте подключение к интернету.";
  }
  if (message?.includes("timeout")) {
    return "Превышено время ожидания. Повторите запрос позднее.";
  }
  return message;
};

export type errorHandlerSagaType = {
  response: any;
  processType?: string;
  handledStatuses?: Array<number>;
};

export function* errorHandlerSaga({
  response: Error,
  processType,
  handledStatuses,
}: errorHandlerSagaType) {
  try {
    const response = Error?.response;
    console.log("errorHandler saga called");

    console.log(Error.request);
    console.log(Error.message);

    const search: string = yield select(
      (state: StateType) => state.router.location.search
    );

    const query: {
      [key: string]: string;
    } = yield select((state: StateType) => state.router.location.query);

    if (processType) {
      yield put(setProcessLoading(processType, false));
    }
    // No error object case
    if (!Error) {
      yield put(
        uiReducer.actions.setModal({
          status: "error",
          message: "Неизвестная ошибка",
        })
      );

      return;
    }

    // No response case
    if (!response) {
      yield put(
        uiReducer.actions.setModal({
          status: "error",
          message:
            getTextFromAxiosErrorMessage(Error?.message) ||
            "Неизвестная ошибка",
        })
      );

      return;
    }

    // Mapping logic
    /* trying map errors to fields */
    const firstError = getFirstErrorFromResponse(response);
    if (firstError) {
      yield put(errorsReducer.actions.setGlobalError(firstError));

      if (response.status == 400 || response.status == 401) {
        yield put(errorsReducer.actions.setFieldsErrors(errorMapper(response)));
      }

      if (response.status == 401) {
        if (
          response.data.data.errors[0].message.includes("Срок действия токена истёк") ||
          response.data.data.errors[0].message.includes("Пользователь не авторизован")
        ) {
          yield put(authSagaActions.refreshToken());
          return;
        } else {
          yield call(logout.saga);
          return;
        }
      }
    }

    /* handle 5XX errors */
    if (response.status >= 500) {
      yield put(
        uiReducer.actions.setModal({
          status: "error",
          message: "Временная 500 ошибка сервера",
        })
      );
      return;
    }

    /* if status was not handled by saga, set global modal */
    if (!handledStatuses?.includes(response.status)) {
      yield put(
        uiReducer.actions.setModal({
          status: "error",
          message: firstError || "Ошибка.",
        })
      );
    }
  } catch (e: any) {
    /* handle error handling */
    yield put(
      uiReducer.actions.setModal({
        status: "error",
        message: "Unhandled error.",
      })
    );
  }
}

function* errorHandlerReduxWrapper({
  payload,
  type,
}: PayloadAction<errorHandlerSagaType>) {
  yield errorHandlerSaga(payload);
}

export function* errorWatcherSaga() {
  yield takeEvery(sagaActions.error, errorHandlerReduxWrapper);
}
