export const constantStrings = {
  telegramLinkForSupport: "https://t.me/Creddy_support_bot",
  phoneNumberForSupport: "88007078665",
  phoneNumberForSupportMask: "8 800 707 86-65",
  whatsappNumberForSupport: "+79801460818",
};

export const linksToDocumentsOnStatic = {
  inf: "https://static.creddy.ru/Информация_об_условиях_предоставления,_использования_и_возврата_микрозайма_займа.pdf",
  rules:
    "https://static.creddy.ru/Правила_предоставления_микрозаймов_ООО_МКК_Кредди.pdf",
  pd: "https://static.creddy.ru/Согласие_на_обработку_ПД,_КИ_обезличенное.pdf",
  consentPd: "https://static.creddy.ru/Согласие_на_обработку_ПД.pdf",
  asp: "https://static.creddy.ru/Соглашение_об_использовании_АСП.pdf",
  mb: "https://static.creddy.ru/Уведомление_о_предоставлении_льготного_периода_и_изменении_условий_Договора_потребительского_микрозайма_369818977_от_21.06.2023г.pdf",
  gt: "https://static.creddy.ru/Общие_условия.pdf",
};
