import { PayloadAction } from "@reduxjs/toolkit";
import { sendingLoanTermsType } from "../../../api/main/application/types";
import { processStateController } from "../../util/helpers/processStateController";
import { call, put, select } from "redux-saga/effects";
import { sagaApiType } from "../../util/types";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import { API } from "../../../api";
import {
  applicationReducer,
  applicationReducerSelectors,
} from "../../../store/reducers/application";
import { redirect } from "../../util/helpers/redirect";
import { routes } from "../../../routes";
import { userSaleApi } from "../../../api/main/user_sale";
import { sendMetrics } from "../../../services/yandexMetrika";

export function* sendingLoanTermsSaga({
  payload,
  type: actionType,
}: PayloadAction<Omit<sendingLoanTermsType, "applicationId">>) {
  const process = processStateController(actionType);

  try {
    yield process.start();

    const applicationId: number = yield select(
      applicationReducerSelectors.getApplicationId
    );

    // post /order
    const response: sagaApiType = yield call(
      API.main.application.sendingLoanTerms,
      {
        applicationId,
        amount: payload.amount,
        loan_term: payload.loan_term,
      }
    );

    if (response.status === 201) {
      sendMetrics("accept offer ok");
    }

    // пока лежит тута, потом перенести в сагу для loan_processing страницы
    yield call(userSaleApi.code, {
      applicationId,
    });

    /*
      Выключаем тут отправку смс на монтирование /code/contract,
      чтобы не задублировать post /loan_contract
     */
    yield put(
      applicationReducer.actions.setMakeRepeatResend({
        value: false,
      })
    );

    /*
      Пока замокан бек, скипаем страницу loan_processing и сразу летим на code/contract
      кста где-то есть редирект на loan_processing в авто аусе, возможно он тут вообще не нужен
     */
    // yield redirect(routes.application.loan_processing);
    // yield redirect(routes.application.code.contract);
    yield redirect(routes.application.statuses.payment_system_response);
  } catch (e) {
    yield call(errorHandlerSaga, {
      response: e,
    });
  } finally {
    yield process.stop();
  }
}
