import React, { FC } from "react";
import styles from "./index.module.css";
import { Icons } from "assets/icons";
import { stringHelpers } from "../../../../../helpers/string";

type propsType = {
  phoneNumber: string;
  onClick: () => void;
};
export const PhoneEditable: FC<propsType> = ({ phoneNumber, onClick }) => {
  return (
    <div className={styles.phone_text}>
      {stringHelpers.phoneNormalize(phoneNumber as string)}
      <Icons.ui.PencilNewPhone
        className={styles.pencil_icon}
        onClick={onClick}
      />
    </div>
  );
};
