import React, { useState } from "react";
import { createFC } from "../../../../../helpers/createFC";
import { PhoneEditable } from "../../components/PhoneEditable";
import { routes } from "../../../../../routes";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { StateType } from "../../../../../store";
import { Button } from "../../../../ui/Button";
import styles from "./index.module.css";
import { applicationApi } from "../../../../../api/main/application";
import { userReducerSelectors } from "../../../../../store/reducers/user/selectors";
import { authApi } from "../../../../../api/auth";
import { useCountdown } from "../../../../../helpers/date/useCountdown";
import { isMobile } from "react-device-detect";

type propsType = {};

export const ToMobileDevice = createFC((props: propsType) => {
  const history = useHistory();
  const phoneNumber = useSelector(
    (state: StateType) => state.userReducer.phone
  );

  const IsHasSignedPdn = useSelector(userReducerSelectors.getIsHasSignedPdn);

  const [retryCount, setRetryCount] = useState(0);

  const { timeLeft, setTimeLeft } = useCountdown(0);
  console.log("timeLeft", timeLeft);

  // Регистрация нового пользователя (нет в БД) - надо отправлять -POST/user/{user_id}/code в АУС
  // Пользователь есть в  бд, есть заявка - надо отправлять -PUT/user/{user_id}/continue-application-sms МЕЙН
  const smsRequestResolver = () => {
    if (IsHasSignedPdn) {
      return authApi.code({
        smsTemplate: isMobile ? "logon_mobile" : "logon_desktop",
      });
    } else {
      return authApi.code({
        smsTemplate: isMobile ? "register_mobile" : "register_desktop",
      });
    }
  };

  return (
    <div>
      {phoneNumber && (
        <PhoneEditable
          phoneNumber={phoneNumber}
          onClick={() => history.push(routes.auth.phone)}
        />
      )}

      {typeof timeLeft !== "undefined" && (
        <div className={styles.counter}>
          <div className={styles.retryCount}>
            {/*{retryCount > 0 && <>Попытка {retryCount} из 5</>}*/}
            Попытка {retryCount} из 5
          </div>
        </div>
      )}

      <Button
        onClick={() => {
          smsRequestResolver();
          setTimeLeft(60);
          setRetryCount((prevState) => prevState + 1);
        }}
        className={styles.smsButton}
        disabled={Boolean(retryCount === 5)}
        loading={Boolean(timeLeft)}
      >
        Отправить СМС
      </Button>

      <div className={styles.bottom_subTitle}>
        Нажимая на кнопку “Отправить СМС” вы даете <br /> согласие на отправку
        вам сообщения со ссылкой <br /> для входа в личный кабинет.
      </div>
      {/*<Button*/}
      {/*  variant={"underline"}*/}
      {/*  className={styles.toMainPage}*/}
      {/*  onClick={() => (window.location.href = "https://creddy.ru/")}*/}
      {/*>*/}
      {/*  На главную страницу*/}
      {/*</Button>*/}
    </div>
  );
});
