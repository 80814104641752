import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import { MobilePageContainer } from "../../../../../ui/Containers/MobilePageContainer";
import { RoundContainer } from "../../../../../ui/Containers/RoundContainer";
import { SBPDataBlock } from "../../AccountConfirmation/SBPDataBlock";
import { Button } from "components/ui/Button";
import { SBPSupportBlock } from "../../AccountConfirmation/SBPSupportBlock";
import { Icons } from "assets/icons";
import { useHistory, useParams } from "react-router-dom";
import { SBPCheckErrorType } from "../../../../../../types/application/types";
import { useDispatch, useSelector } from "react-redux";
import { applicationSagaActions } from "../../../../../../sagas/application/actions";
import { API } from "../../../../../../api";
import { applicationReducerSelectors } from "../../../../../../store/reducers/application";
import { routes } from "../../../../../../routes";
import { processLoading } from "../../../../../../store/auxiliary/loadingReducer";
import { Loader } from "../../../../../ui/Loader";
import { FillingAssistance } from "../../../../../ui/FillingAssistance";
import { SmartForm } from "../../../../../hocs/SmartForm";
import { RangeWithTitle } from "../../../../../ui/RangeWithTitle";
import { monthRangeData } from "../../NewLoan";
import { Radio } from "../../../../../ui/Radio";
import { Select } from "../../../../../ui/Selects/Select";
import { useForm, useWatch } from "react-hook-form";
import { useGetBanks } from "../../SPBDefineProcess/useGetBanks";
import { sendMetrics } from "../../../../../../services/yandexMetrika";
import { lsController } from "../../../../../../store/auxiliary/localStorage";
import { ErrorBanner } from "./errorBannerSBP";

type propsType = {};

export const SBPCheckError: FC<propsType> = () => {
  const history = useHistory();

  const form = useForm();

  const { transformedDataForSelect, isLoading } = useGetBanks();

  const applicationId = useSelector(
    applicationReducerSelectors.getApplicationId
  );

  const bankName = lsController.get("bank_name");

  const loanDataAmount = useWatch({
    control: form.control,
    name: "loan_data.amount",
  });

  const [selectWatch, bankIdWatch] = useWatch({
    control: form.control,
    name: ["select", "bank_id"],
  });

  const loanDataTerm = useWatch({
    control: form.control,
    name: "loan_data.loan_term",
  });

  const [errorMessageBank, setErrorMessageBank] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  const validateBank = () => {
    if (!selectWatch && !bankIdWatch) {
      setErrorMessageBank("Поле обязательно к заполнению!");
    } else {
      setErrorMessageBank("");
    }
  };

  function getBankId(bankName: string): string | undefined {
    const bank = transformedDataForSelect.find((item) =>
      item?.text?.includes(bankName)
    );

    return bank?.id?.toString();
  }

  const SberBankId = getBankId("Сбербанк");
  const VTBBankId = getBankId("Банк ВТБ");
  const TinkoffBankId = getBankId("Тинькофф Банк");

  useEffect(() => {
    if (selectWatch?.text && bankIdWatch) {
      form.setValue("select", { text: "" });
    }
  }, [bankIdWatch]);

  useEffect(() => {
    if (bankIdWatch && selectWatch?.text) {
      form.setValue("bank_id", null);
    }
  }, [selectWatch]);

  useEffect(() => {
    form.setValue(
      "loan_data.amount",
      Number(lsController.get("amount")) || 500000
    );
    form.setValue(
      "loan_data.loan_term",
      Number(lsController.get("period_month")) || 48
    );
  }, []);

  const handleConfirm = (data: any) => {
    setLoading(true);
    let bank_id;
    if (data?.select?.text) {
      bank_id = data?.select?.id;
    }
    if (data?.bank_id) {
      bank_id = Number(data?.bank_id);
    }

    validateBank();

    API.main.application
      .defineSBPBankAfterError({
        applicationId,
        bank_id,
      })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          history.push(routes.application.statuses.signed_issuing_process);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  if (isLoading) {
    return (
      <MobilePageContainer>
        <Loader variant={"large"} color={"purple"} className={styles.loader} />
      </MobilePageContainer>
    );
  }

  return (
    <MobilePageContainer>
      <div className={styles.container}>
        <RoundContainer className={styles.ignorePadding}>
          <SmartForm
            form={form}
            submit={handleConfirm}
            className={styles.popularBanks}
          >
            <ErrorBanner bankName={bankName} />
            <div className={styles.title}>Выберите другой банк</div>
            <Radio
              // error={form.formState.bank_id.errors}
              name={"bank_id"}
              value={SberBankId}
              iconPosition={"right"}
              type={"radio"}
              iconClassName={styles.radioIcon}
              label={
                <div className={styles.popularBank}>
                  {/*<Icons.logos.AlphaBank className={styles.bankIcon} />*/}
                  <div className={styles.bankLabel}>СберБанк</div>
                </div>
              }
            />

            <Radio
              name={"bank_id"}
              value={VTBBankId}
              iconPosition={"right"}
              type={"radio"}
              iconClassName={styles.radioIcon}
              label={
                <div className={styles.popularBank}>
                  {/*<Icons.logos.UralsibBank className={styles.bankIcon} />*/}
                  <div className={styles.bankLabel}>Банк ВТБ</div>
                </div>
              }
            />

            <Radio
              name={"bank_id"}
              value={TinkoffBankId}
              iconPosition={"right"}
              type={"radio"}
              iconClassName={styles.radioIcon}
              label={
                <div className={styles.popularBank}>
                  {/*<Icons.logos.TinkoffBank className={styles.bankIcon} />*/}
                  <div className={styles.bankLabel}>Тинькофф Банк</div>
                </div>
              }
            />
          </SmartForm>

          <Select
            name={"select"}
            control={form.control}
            setValue={form.setValue}
            options={transformedDataForSelect}
            selectTitle={"Выберите банк"}
            // selectTitle={"Другой банк"}
            support={<Icons.ui.DropDown />}
          />
          <div className={styles.instruction}>
            <Button variant={"underline"} className={styles.instructionButton}>
              Инструкция по подключению СБП
            </Button>
            <Icons.ui.Document_noContainer className={styles.documentIcon} />
          </div>
        </RoundContainer>

        <Button
          className={styles.confirmButton}
          onClick={form.handleSubmit(handleConfirm)}
          loading={loading}
        >
          Подтвердить
        </Button>
        <div className={styles.error_message}>{errorMessageBank}</div>
      </div>
    </MobilePageContainer>
  );
};
