import React, { FC } from "react";
import cn from "classnames";
import styles from "./index.module.css";
import { RangeSlider } from "../Range";
import { RangeType } from "../Range/types";
import { InputEditableNumber } from "../Inputs/InputEditableNumber";
import { RangeSquare } from "../RangeSquare";

type propsType = {
  textInformation?: boolean;
  amount?: number;
  amountForTextInformation?: number;
  amountError?: boolean;
  conditionForLockRangeAmount?: boolean;
  conditionForLockRangeLoanTerms?: boolean;
  period?: boolean;
  variant?: "round" | "square";
  title: string;
  name: string;
  containerClass?: string;
  hasInput?: boolean;
  disabled?: boolean;
  onChange?: () => void;
  getSchedule?: () => any;
  loading?: boolean;
} & Omit<RangeType, "values" | "setValues">;

export const RangeWithTitleVariant2: FC<propsType> = ({
  amountForTextInformation,
  textInformation,
  amount,
  amountError,
  conditionForLockRangeAmount,
  conditionForLockRangeLoanTerms,
  variant = "round",
  title,
  name,
  label,
  containerClass,
  hasInput = true,
  ...rangeProps
}) => {
  const [values, setValues] = React.useState([rangeProps.min]);

  if (variant === "square")
    return (
      <div
        className={cn(styles.container, {
          [`${containerClass}`]: containerClass,
        })}
      >
        <div className={styles.title_wrapper}>
          <p className={styles.title}>{title}</p>
        </div>

        <RangeSquare
          {...rangeProps}
          className={styles.range}
          name={name}
          values={values}
          setValues={setValues}
        />
      </div>
    );

  return (
    <div
      className={cn(styles.container, {
        [`${containerClass}`]: containerClass,
      })}
    >
      <div className={styles.title_wrapper}>
        <p className={styles.title}>{title}</p>
        {hasInput ? (
          <InputEditableNumber
            maxLength={rangeProps.maxLength}
            amountError={amountError}
            period={rangeProps.period}
            name={name}
            className={styles.input}
            max={rangeProps.max}
            min={rangeProps.min}
            step={10000}
            loading={rangeProps.loading}
            onIconClick={rangeProps.getSchedule}
            defaultValue={rangeProps.defaultValue}
            conditionForLockRangeAmount={conditionForLockRangeAmount}
            conditionForLockRangeLoanTerms={conditionForLockRangeLoanTerms}
          />
        ) : (
          <div className={styles.value}>
            {rangeProps.rangeData &&
              rangeProps.rangeData[
                (values[0] + (rangeProps.step || 1) - rangeProps.min) /
                  (rangeProps.step || 1) -
                  1
              ]}
          </div>
        )}
      </div>

      <RangeSlider
        amountForTextInformation={amountForTextInformation}
        textInformation={textInformation}
        amount={amount}
        conditionForLockRangeAmount={conditionForLockRangeAmount}
        conditionForLockRangeLoanTerms={conditionForLockRangeLoanTerms}
        {...rangeProps}
        className={styles.range}
        name={name}
        values={values}
        setValues={setValues}
      />
    </div>
  );
};
