import React, { AllHTMLAttributes, FC, useEffect } from "react";
import styles from "./index.module.css";
import { useFormContext, useWatch } from "react-hook-form";
import cn from "classnames";
import { useCodeFieldLogic } from "../CodeField/useCodeFieldLogic";
import { VinField } from "./VinField";
import { useVinFieldLogic } from "./VinField/useVinFieldLogic";
import { LicensePlate } from "./LicensePlate";
import { Icons } from "../../../assets/icons";
import { Switcher } from "../Switcher";
import { lsController } from "../../../store/auxiliary/localStorage";
import { stringHelpers } from "../../../helpers/string";

type propsType = {
  variant?: "default" | "onlyLicensePlate" | "onlyVin";
  title?: boolean;
  setError: any;
} & AllHTMLAttributes<HTMLDivElement>;

export const VinAndLicensePlate: FC<propsType> = ({
  variant = "default",
  className,
  required,
  ...props
}) => {
  const {
    setValue,
    control,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  const isChecked = useWatch({
    control,
    name: "vin_or_license_plate_selected",
  });

  const vinShow = useWatch({
    control,
    name: "vin_number",
  });

  const regShow = useWatch({
    control,
    name: "reg_number",
  });

  const grz = stringHelpers.decodeUrlQueryString(lsController.get("grz"));
  const vin = stringHelpers.decodeUrlQueryString(lsController.get("vin"));

  // useEffect(() => {
  //   if (vinShow?.length < 17 && vinShow?.length > 0) {
  //     props.setError("vin_number", {
  //       type: "custom",
  //       message: "3аполните поле",
  //     });
  //   } else {
  //     clearErrors("vin_number");
  //   }
  // }, [vinShow, isChecked]);

  // useEffect(() => {
  //   if (regShow?.length < 8 && regShow?.length > 0) {
  //     props.setError("reg_number", {
  //       type: "custom",
  //       message: "3аполните поле",
  //     });
  //   } else {
  //     clearErrors("reg_number");
  //   }
  // }, [regShow, isChecked]);

  // useEffect(() => {
  //   if (isChecked && (!vinShow || vinShow && vinShow?.length === 17)) {
  //     clearErrors("vin_number");
  //   } else if (!isChecked && regShow && regShow?.length === 8) {
  //     clearErrors("reg_number");
  //   }
  // }, [isChecked]);

  const mainFieldProps = useVinFieldLogic({
    codeLength: 6,
    // для нужной клавиатурки в телефоне. Поведение инпута с буквами определяется в
    inputTypes: ["text", "tel", "tel", "tel", "text", "text"],
    // defaultValue: grz?.substr(0, 6),
    transformToCyrillic: true,
    onChange: () => {
      clearErrors("vin_or_reg_number");
    },
    onFocus: () => {
      clearErrors("reg_number");
    },
  });
  const regionFieldProps = useCodeFieldLogic({
    codeLength: 3,
    // defaultValue: grz?.substr(6),
    onChange: () => {
      clearErrors("vin_or_reg_number");
    },
    onFocus: () => {
      clearErrors("reg_number");
    },
    fieldsArray: mainFieldProps.pastedRegionPart
  });

  // * CODEFIELD
  const vinFieldProps = useVinFieldLogic({
    codeLength: 17,
    // defaultValue: vin && vin,
    onChange: () => {
      clearErrors("vin_or_reg_number");
    },
    onFocus: () => {
      clearErrors("vin_number");
    },
  });

  useEffect(() => {
    setValue("vin_number", vinFieldProps.value);
  }, [vinFieldProps.value]);

  useEffect(() => {
    setValue("reg_number", mainFieldProps.value + regionFieldProps.value);
  }, [mainFieldProps.value, regionFieldProps.value]);

  if (variant === "onlyLicensePlate") {
    return (
      <div className={cn(styles.container, className)}>
        <div
          className={cn(styles.content, {
            // [styles.content__error]: errors?.reg_number?.message,
          })}
        >
          <LicensePlate
            huge
            mainFieldProps={mainFieldProps}
            regionFieldProps={regionFieldProps}
            error={errors?.reg_number?.message as any}
          />
        </div>

        {errors?.reg_number?.message && !isChecked && (
          <div className={styles.error_wrapper}>
            {/*<Icons.ui.ErrorExclamation />*/}
            {errors?.reg_number?.message}
          </div>
        )}
      </div>
    );
  }

  if (variant === "onlyVin") {
    return (
      <div className={cn(styles.container, className)}>
        <div
          className={cn(styles.content, {
            // [styles.content__error]: errors?.vin_number?.message,
          })}
        >
          <VinField
            {...vinFieldProps}
            error={errors?.vin_number?.message as any}
            className={styles.code_field}
          />
        </div>

        {errors?.vin_number?.message && !isChecked && (
          <>
            <div className={styles.error_wrapper}>
              {/*<Icons.ui.ErrorExclamation />*/}
              {errors?.vin_number?.message}
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <div className={cn(styles.container, className)}>
      <div
        className={cn(styles.content, {
          // [styles.content__error]: errors?.vin_number?.message,
        })}
      >
        <div
          className={cn(styles.header, {
            [styles.header_with_title]: props.title,
          })}
        >
          {props.title && <div className={styles.title}>VIN или госномер</div>}
          <Switcher
            huge
            name={"vin_or_license_plate_selected"}
            switchVariant={"large"}
            innerLabels={["Госномер", "VIN"]}
          />
        </div>

        {isChecked ? (
          <VinField
            {...vinFieldProps}
            error={errors?.code?.message as any}
            className={styles.code_field}
          />
        ) : (
          <LicensePlate
            huge
            mainFieldProps={mainFieldProps}
            regionFieldProps={regionFieldProps}
            error={errors?.code?.message as any}
          />
        )}
      </div>

      {errors?.vin_number?.message && isChecked && (
        <div className={styles.error_wrapper}>
          <Icons.ui.ErrorExclamation />
          {errors?.vin_number?.message}
        </div>
      )}

      {errors?.reg_number?.message && !isChecked && (
        <div className={styles.error_wrapper}>
          <Icons.ui.ErrorExclamation />
          {errors?.reg_number?.message}
        </div>
      )}

      {/*{errors?.vin_or_reg_number?.message && (*/}
      {/*  <div className={styles.error_wrapper}>*/}
      {/*    <Icons.ui.ErrorExclamation />*/}
      {/*    /!*Проверьте правильность введенных данных.*!/*/}
      {/*    {errors?.vin_or_reg_number?.message}*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};
