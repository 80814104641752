import React, { FC, ReactFragment } from "react";
import styles from "./index.module.css";
import { motion } from "framer-motion";
import { manualAnimation } from "./animate";
import { Icons } from "assets/icons";
import { DocumentApiTypes } from "../../../../../types/documents/types";
import { Images } from "../../../../../assets/img";
import { ManualHeader } from "../../../../pages/Application/components/ManualHeader";
import carSalonManual from "../../../../../assets/img/carInstructions/car_salon_manual.svg";

type propsType = {
  isOpenManual: boolean;
  setIsOpenManual: React.Dispatch<React.SetStateAction<boolean>>;
  handleChange?: (e: any) => void;
} & DocumentApiTypes.documentTypeField;

const carPhotoText = (
  <>
    Он должен быть чистым и хорошо освещен,
    <br />
    чтобы мы могли его рассмотреть.
    <br />
    <br />
    <div>
      Мы попросим вас сделать фото как
      <br />
      показано на примере
    </div>
    {/*Он должен быть чистым и хорошо освещен, чтобы мы могли его рассмотреть.*/}
    {/*<br />*/}
    {/*<br />*/}
    {/*Мы попросим вас снять автомобиль с четырех ракурсов, как показано на примерах далее, а так же панель управления*/}
    {/*заведенного авто и VIN-номер, который находится под лобовым стеклом*/}
  </>
);

const carPhotoTitle = <>Фото автомобиля.</>;

const manualData: {
  [key in DocumentApiTypes.documentType]: {
    title: ReactFragment;
    title2?: ReactFragment;
    title3?: ReactFragment;
    text: ReactFragment;
    text2?: ReactFragment;
    text3?: ReactFragment;
    imgTitle?: ReactFragment;
    subTitle?: ReactFragment;
    imgSource: string;
  };
} = {
  /* Data for documents page */
  passport_main: {
    title: (
      <>
        Фото паспорта. <br /> Разворот с фото
      </>
    ),
    text: (
      <>
        Сделайте фото первого разворота паспорта (страницы 2-3).
        <br />
        <br />
        <ul className={styles.ul_container}>
          <div className={styles.title_ul}>На фото:</div>
          <li>не должно быть бликов</li>
          <li>должен полностью поместиться паспорт без обрезов</li>
          <li>пальцы не должны заслонять серию и номер</li>
          <li>фото не должно быть мутным или нечетким</li>
        </ul>
        <br />
      </>
    ),
    // imgTitle: <>Разворот с личными данными</>,
    // subTitle: <>Сделайте фото, как на примере</>,
    imgSource: Images.documentsInstructions.passport.firstPage,
  },
  repledge_certificate_of_debt_status: {
    title: <>Справка</>,
    text: (
      <>
        Сделайте фото справки или загрузите скан копию либо скриншот. Документ
        должен быть читаем, все данные видны
      </>
    ),
    // imgTitle: <>Разворот с личными данными</>,
    // subTitle: <>Сделайте фото, как на примере</>,
    imgSource: Images.repledgeInstructions.debtCertificate,
  },
  repledge_loan_contract: {
    title: <>Договор займа.</>,
    text: (
      <>
        Сделайте постраничные фото договора или загрузите скан копию либо
        скриншоты каждой страницы. При фотографировании одно фото - одна
        страница документа. Документ должен быть читаем, все данные видны
      </>
    ),
    // imgTitle: <>Разворот с личными данными</>,
    // subTitle: <>Сделайте фото, как на примере</>,
    imgSource: Images.repledgeInstructions.loanContract,
  },
  repledge_loan_contract_add: {
    title: <>Договор займа.</>,
    text: (
      <>
        Сделайте постраничные фото договора или загрузите скан копию либо
        скриншоты каждой страницы. При фотографировании одно фото - одна
        страница документа. Документ должен быть читаем, все данные видны
      </>
    ),
    // imgTitle: <>Разворот с личными данными</>,
    // subTitle: <>Сделайте фото, как на примере</>,
    imgSource: Images.repledgeInstructions.loanContract,
  },
  optional_passport_main: {
    title: (
      <>
        Фото паспорта. <br /> Разворот с фото
      </>
    ),
    text: (
      <>
        Он должен быть чистым и хорошо освещен, чтобы мы могли его рассмотреть.
        <br />
        <br />
        Мы попросим вас сделать фото как показано на примере
        <br />
      </>
    ),
    // imgTitle: <>Разворот с личными данными</>,
    // subTitle: <>Сделайте фото, как на примере</>,
    imgSource: Images.documentsInstructions.passport.firstPage,
  },
  passport_registration: {
    title: (
      <>
        Фото паспорта. <br />
        Разворот с пропиской
      </>
    ),
    text: (
      <>
        Сделайте фото разворота с пропиской
        <br />
        <br />
        <ul className={styles.ul_container}>
          <div className={styles.title_ul}>На фото:</div>
          <li>не должно быть бликов</li>
          <li>полностью разворот паспорта без обрезки</li>
          <li>пальцы не должны заслонять серию и номер</li>
          <li>фото не должно быть мутным или нечетким</li>
          <li>нужен разворот с последним штампом о прописке</li>
        </ul>
        <br />
      </>
    ),
    // subTitle: <>Сделайте фото, как на примере.</>,
    // imgTitle: <>Разворот с последним местом прописки</>,

    imgSource: Images.documentsInstructions.passport.registration,
  },
  optional_passport_registration: {
    title: (
      <>
        Фото паспорта. <br />
        Разворот с пропиской
      </>
    ),
    text: (
      <>
        Он должен быть чистым и хорошо освещен, чтобы мы могли его рассмотреть.
        <br />
        <br />
        Мы попросим вас сделать фото как показано на примере
        <br />
      </>
    ),
    // subTitle: <>Сделайте фото, как на примере.</>,
    // imgTitle: <>Разворот с последним местом прописки</>,

    imgSource: Images.documentsInstructions.passport.registration,
  },
  driver_license_front: {
    title: (
      <>
        Водительское удостоверение <br /> 1 сторона
      </>
    ),
    text: (
      <>
        Он должен быть чистым и хорошо освещен, чтобы мы могли его рассмотреть.
        <br />
        <br />
        Мы попросим вас сделать фото как показано на примере
        <br />
      </>
    ),
    // imgTitle: <>Лицевая сторона водительского удостоверения</>,
    // subTitle: <>Сделайте фото, как на примере.</>,
    imgSource: Images.documentsInstructions.driverLicense.front,
  },
  driver_license_back: {
    title: (
      <>
        Водительское удостоверение <br /> 2 сторона
      </>
    ),
    text: (
      <>
        Он должен быть чистым и хорошо освещен, чтобы мы могли его рассмотреть.
        <br />
        <br />
        Мы попросим вас сделать фото как показано на примере
        <br />
      </>
    ),
    // imgTitle: <>Обратная сторона водительского удостоверения</>,
    // subTitle: <>Сделайте фото, как на примере.</>,
    imgSource: Images.documentsInstructions.driverLicense.back,
  },
  selfie_with_passport: {
    title: <>Подготовьте ваш паспорт</>,
    text: (
      <>
        Сделайте фото с паспортом сами или попросите вас сфотографировать.
        Должно быть хорошо видно вас и паспорт
      </>
    ),
    // imgTitle: <>Селфи с паспортом</>,
    // subTitle: <>Сделайте фото, как на примере.</>,
    imgSource: Images.documentsInstructions.passport.passportSelfie,
  },
  vehicle_registration_certificate_front: {
    title: (
      <>
        Фото СТС автомобиля. <br /> Данные авто
      </>
    ),
    text: (
      <>
        Сделайте фото СТС, сторона с данными автомобиля. Не должно быть бликов
      </>
    ),
    // imgTitle: <>СТС с данными автомобиля</>,
    // subTitle: <>Сделайте фото, как на примере.</>,
    imgSource: Images.documentsInstructions.registration.front,
  },
  vehicle_registration_certificate_back: {
    title: (
      <>
        Фото СТС автомобиля. <br />
        Данные владельца
      </>
    ),
    text: (
      <>Сделайте фото СТС, сторона с данными владельца. Не должно быть бликов</>
    ),
    // imgTitle: <>СТС с данными собственника</>,
    // subTitle: <>Сделайте фото, как на примере.</>,
    imgSource: Images.documentsInstructions.registration.back,
  },
  pts_front: {
    title: (
      <>
        Фото ПТС автомобиля <br />
        Сторона с данными авто
      </>
    ),
    text: (
      <>
        Сделайте фото ПТС, сторона с данными автомобиля. Полностью весь разворот
      </>
    ),
    // imgTitle: <>Лицевая сторона ПТС</>,
    // subTitle: <>Сделайте фото, как на примере.</>,
    imgSource: Images.documentsInstructions.pts.front,
  },
  pts_back: {
    title: (
      <>
        Фото ПТС автомобиля. <br /> Внутренняя сторона{" "}
      </>
    ),
    text: (
      <>
        Сделайте фото внутренней стороны ПТС.
        <br />
        Полностью весь разворот
      </>
    ),
    // imgTitle: <>Оборотная сторона ПТС</>,
    // subTitle: <>Сделайте фото, как на примере.</>,
    imgSource: Images.documentsInstructions.pts.back,
  },

  /* Data for car photos page */
  front_left: {
    title: carPhotoTitle,
    text: carPhotoText,
    imgSource: Images.carPhotoInstructions.car.frontLeft,
    imgTitle: <>Слева спереди</>,
    subTitle: <>Сфотографируйте автомобиль, как на примере</>,
  },
  front_right: {
    title: carPhotoTitle,
    text: carPhotoText,
    imgSource: Images.carPhotoInstructions.car.frontRight,
    imgTitle: <>Справа спереди</>,
    subTitle: <>Сфотографируйте автомобиль, как на примере</>,
  },
  back_right: {
    title: carPhotoTitle,
    text: carPhotoText,
    imgSource: Images.carPhotoInstructions.car.backRight,
    imgTitle: <>Справа сзади</>,
    subTitle: <>Сфотографируйте автомобиль, как на примере</>,
  },
  back_left: {
    title: carPhotoTitle,
    text: carPhotoText,
    imgSource: Images.carPhotoInstructions.car.backLeft,
    imgTitle: <>Слева сзади</>,
    subTitle: <>Сфотографируйте автомобиль, как на примере</>,
  },
  dashboard: {
    title: <>Фото автомобиля.</>,
    text: (
      <>
        Сделайте фото приборной панели при заведенном моторе. На одометре должен
        быть виден пробег
      </>
    ),
    imgSource: Images.carPhotoInstructions.dashboardManual,
    imgTitle: <>Приборная панель</>,
    // subTitle: <>Сфотографируйте панель, как на примере</>,
  },
  vin: {
    title: <>VIN или номер кузова</>,
    // title2: <>VIN на корпусе:</>,
    text: (
      <>
        <div className={styles.title_vin}>
          Только фото на машине, не документ
        </div>
        <ul className={styles.ul_container}>
          <span>Местоположение VIN или номера кузова бывает:</span>
          <li>под лобовым стеклом</li>
          <li>под капотом на специальной табличке</li>
          <li>под креслом водителя под ковриком (отодвинуть сиденье)</li>
          <li>на стойке водительской двери при открытии</li>
        </ul>
      </>
    ),
    // text2: (
    //   <>
    //     - уберите/отодвиньте закрывающие детали (переднее сиденье, часть
    //     тканевой обшивки и т.д.);
    //     <br />- VIN должен быть хорошо освещён и читаем, чтобы мы могли его
    //     рассмотреть.
    //   </>
    // ),
    // text3: (
    //   <>
    //     Если вы не можете найти VIN-номер на своём автомобиле, напишите нам в
    //     поддержку и мы поможем.
    //   </>
    // ),
    imgSource: Images.carPhotoInstructions.vinManual,
    // imgTitle: <>Сфотографируйте VIN, как на примере</>,
  },
  interior: {
    title: <>Фото передней части салона</>,
    text: (
      <>
        Сделайте фото салона, на кадре должны быть передние кресла и рычаг
        коробки переключения передач
      </>
    ),
    // imgTitle: <>Разворот с личными данными</>,
    // subTitle: <>Сделайте фото, как на примере</>,
    imgSource: Images.carPhotoInstructions.carSalonManual,
  },
  selfie_with_car: {
    title: <>Фото с автомобилем</>,
    text: (
      <>
        Сделайте фотографию, на которой мы увидим вас и ваш автомобиль так,
        чтобы госномер попал в кадр. Сфотографируйтесь с автомобилем, как на
        примере, или попросите кого-нибудь вас сфотографировать
      </>
    ),
    imgSource: Images.carPhotoInstructions.selfieWithCarManual,
  },
};

/*
  document_type в эту компоненту приходит пропсом из DocumentsArea через File.
  что такое file.document_type в File -- надо раскурить и переименовать, а может и выпилить вообще
 */

export const Manual: FC<propsType> = ({
  isOpenManual,
  setIsOpenManual,
  handleChange,
  document_type,
}) => {
  const setManualToFalse = () => {
    document.querySelector("html")!.style.overflow = "auto";
    setIsOpenManual(false);
  };

  const openManual = () => {
    document.querySelector("html")!.style.overflow = "hidden";
  };

  React.useEffect(() => {
    return () => {
      document.querySelector("html")!.style.overflow = "auto";
    };
  }, []);

  const captureValue = () => {
    if (
      document_type === "selfie_with_passport" ||
      document_type === "selfie_with_car"
    ) {
      return "user";
    } else if (
      document_type === "repledge_loan_contract" ||
      document_type === "repledge_certificate_of_debt_status" ||
      document_type === "repledge_loan_contract_add"
    ) {
      return false;
    } else {
      return "environment";
    }
  };

  const acceptValue = () => {
    if (
      document_type === "repledge_loan_contract" ||
      document_type === "repledge_certificate_of_debt_status" ||
      document_type === "repledge_loan_contract_add"
    ) {
      return undefined;
    } else {
      return "image/*";
    }
  };

  return (
    <motion.div
      variants={manualAnimation}
      initial={"close"}
      animate={isOpenManual ? "open" : "close"}
      className={styles.manual}
    >
      {/*<ManualHeader document_type={document_type} />*/}
      <div className={styles.container}>
        <div className={styles.title_container}>
          {" "}
          <div className={styles.title}>
            {manualData?.[document_type]?.title}
          </div>
          <Icons.ui.ArrowHeader
            onClick={setManualToFalse}
            className={styles.arrow}
          />
        </div>
        {manualData?.[document_type]?.imgTitle && (
          <>
            <div className={styles.title}>
              {manualData?.[document_type]?.imgTitle}
            </div>
            {/*<div className={styles.subTitle}>*/}
            {/*    {manualData?.[document_type]?.subTitle}*/}
            {/*</div>*/}

            {/*<div className={styles.text}>*/}
            {/*    {manualData?.[document_type]?.text3}*/}
            {/*</div>*/}
          </>
        )}

        <div className={styles.text}>{manualData?.[document_type]?.text}</div>

        <div className={styles.imgTitle}>
          {manualData?.[document_type]?.title2}
        </div>
        <div className={styles.text}>{manualData?.[document_type]?.text2}</div>

        <img
          className={styles.image}
          src={manualData?.[document_type]?.imgSource}
          alt={"Фото к инструкции"}
        />

        <label className={styles.label}>
          <input
            type="file"
            capture={captureValue()}
            accept={acceptValue()}
            className={styles.input}
            onChange={(e) => {
              handleChange && handleChange(e);
            }}
            onClick={openManual}
          />
          Сделать фото
        </label>
      </div>
    </motion.div>
  );
};
