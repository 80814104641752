import { useEffect, useState } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { VideoCallTimeFormType } from "../../components/pages/Application/pages/Statuses/SentAndScoringProcess";

/**
 * для 9 часов возможны только 30 и 45 мин
 * для 19 часов возможны только 00, 15 и 30 мин
 * для все остальных часов в диапазоне между 9 и 19 возможны 00, 15, 30 и 45 мин
 */

const initHoursArr: Array<string> = ["09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19"];

export const useClock = (
  form: UseFormReturn<VideoCallTimeFormType> & any,
) => {
  const [hoursArr, setHoursArr] = useState<Array<string>>(initHoursArr);
  const [minutesArr, setMinutesArr] = useState<Array<string>>([]);
  const [defaultTime, setDefaultTime] = useState({ hours: 9, minutes: 30 });

  const currentHours = new Date(Date.now()).getHours();
  const currentMinutes = new Date(Date.now()).getMinutes();
  const todayIsTooLate = currentHours > 19 || (currentHours === 19 && currentMinutes > 0);

  const selectedDay = useWatch({
    control: form.control,
    name: "day",
  });

  const selectedHours = useWatch({
    control: form.control,
    name: "hours",
  });

  const selectedMinutes = useWatch({
    control: form.control,
    name: "minutes",
  });

  const getDefaultTime = () => {
    if (currentMinutes >= 16 && currentMinutes <= 30) {
      return { hours: `${currentHours + 1}`, minutes: "00" };
    } else if (currentMinutes >= 31 && currentMinutes <= 45) {
      return { hours: `${currentHours + 1}`, minutes: "15" };
    } else if (currentMinutes >= 46 && currentMinutes <= 59) {
      return { hours: `${currentHours + 1}`, minutes: "30" };
    } else if (currentMinutes >= 1 && currentMinutes <= 15) {
      return { hours: `${currentHours}`, minutes: "45" };
    } else {
      return { hours: `${currentHours}`, minutes: "30" };
    }
  };

  const generateFinalTime = () => {
    let currentYear, currentMonth, currentDate;

    if (selectedDay === "tomorrow") {
      currentYear = new Date(Date.now() + 86400000).getFullYear();
      currentMonth = new Date(Date.now() + 86400000).getMonth();
      currentDate = new Date(Date.now() + 86400000).getDate();
    } else if (selectedDay === "after-tomorrow") {
      currentYear = new Date(Date.now() + 172800000).getFullYear();
      currentMonth = new Date(Date.now() + 172800000).getMonth();
      currentDate = new Date(Date.now() + 172800000).getDate();
    } else {
      currentYear = new Date().getFullYear();
      currentMonth = new Date().getMonth();
      currentDate = new Date().getDate();
    }

    if (selectedHours?.value && selectedMinutes?.value) {
      const finalDate = new Date(
        currentYear,
        currentMonth,
        currentDate,
        Number(selectedHours?.value) + 3,
        Number(selectedMinutes?.value)
      ).toISOString();

      const hours = selectedHours?.value.length === 1 ? `0${selectedHours.value}` : selectedHours.value;

      return `${finalDate.substring(0, 10)} ${hours}${finalDate.substring(13, 19)}`;
    } else {
      return "";
    }
  };

  useEffect(() => {
    form.setValue("day", selectedDay || "today");

    if (todayIsTooLate) {
      form.setValue("day", "tomorrow");
      form.setValue("hours", { value: 9 });
      form.setValue("minutes", { value: 30 });
    } else if (currentHours < 9) {
      form.setValue("hours", { value: 9 });
      form.setValue("minutes", { value: 30 });
    } else {
      setDefaultTime({
        hours: Number(getDefaultTime().hours),
        minutes: Number(getDefaultTime().minutes),
      });
      form.setValue("hours", { value: Number(getDefaultTime().hours) });
      form.setValue("minutes", { value: Number(getDefaultTime().minutes) });
    }
  }, []);

  useEffect(() => {
    if (todayIsTooLate || currentHours < 9 || selectedDay === "tomorrow" || selectedDay === "after-tomorrow") {
      setHoursArr(initHoursArr);
    } else {
      const idx = initHoursArr
        .map((item) => Number(item))
        .indexOf(currentHours);

      if (currentMinutes > 15) {
        setHoursArr(initHoursArr.slice(idx + 1));
      } else {
        setHoursArr(initHoursArr.slice(idx));
      }
    }
  }, [currentHours, selectedDay]);

  useEffect(() => {
    if (selectedDay === "tomorrow" || selectedDay === "after-tomorrow" || todayIsTooLate) {
      setMinutesArr(
        Number(selectedHours?.value) === 9
          ? ["30", "45"]
          : Number(selectedHours?.value) === 19
            ? ["00", "15", "30"]
            : ["00", "15", "30", "45"]
      );
    } else if (currentHours < 9 && Number(selectedHours?.value) === 9) {
      setMinutesArr(["30", "45"]);
    } else if (
      currentHours === Number(selectedHours?.value) &&
      ((currentMinutes > 45 && currentMinutes <= 59) || currentMinutes === 0)
    ) {
      setMinutesArr(Number(selectedHours?.value) === 19 ? ["30"] : ["30", "45"]);
    } else if (
      currentHours + 1 === Number(selectedHours?.value) &&
      currentMinutes > 45 &&
      currentMinutes <= 59
    ) {
      setMinutesArr(Number(selectedHours?.value) === 19 ? ["30"] : ["30", "45"]);
    } else if (
      currentHours === Number(selectedHours?.value) &&
      currentMinutes >= 1 &&
      currentMinutes <= 15
    ) {
      setMinutesArr(["45"]);
    } else if (
      currentHours + 1 === Number(selectedHours?.value) &&
      currentMinutes > 30 &&
      currentMinutes <= 45
    ) {
      setMinutesArr(Number(selectedHours?.value) === 19 ? ["15", "30"] : ["15", "30", "45"]);
    } else {
      setMinutesArr(Number(selectedHours?.value) === 19 ? ["00", "15", "30"] : ["00", "15", "30", "45"]);
    }
  }, [selectedHours, selectedDay]);

  useEffect(() => {
    if (selectedDay === "tomorrow" || selectedDay === "after-tomorrow") {
      setDefaultTime({ hours: 9, minutes: 30 });
    } else {
      setDefaultTime({
        hours: Number(getDefaultTime().hours),
        minutes: Number(getDefaultTime().minutes),
      });
    }
  }, [selectedDay]);

  useEffect(() => {
    if (todayIsTooLate) {
      setHoursArr(initHoursArr);
      setMinutesArr(["00", "15", "30", "45"]);

      form.setValue("day", "tomorrow");
      form.setValue("hours", { value: 9 });
      form.setValue("minutes", { value: 30 });
    }
  }, [todayIsTooLate]);

  return {
    hoursArr,
    minutesArr,
    defaultTime,
    todayIsTooLate,
    currentHours,
    selectedMinutes,
    selectedHours,
    selectedDay,
    videoCallPlannedDate: generateFinalTime(),
  };
};
