import { createFC } from "helpers/createFC";
import styles from "./index.module.css";
import { AllHTMLAttributes } from "react";
import cn from "classnames";

type propsType = {
  children: any;
  isCabinet?: boolean;
} & AllHTMLAttributes<HTMLDivElement>

export const MobilePageContainer = createFC((props: propsType) => {
  return (
    <div
      className={cn(styles.container,  props.className, {
        [styles.cabinet]: props.isCabinet,
      })}
    >
      {props.children}
    </div>
  );
});