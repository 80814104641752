import { PayloadAction } from "@reduxjs/toolkit";
import { verifyCodeType } from "../../../api/auth/types";
import { processStateController } from "../../util/helpers/processStateController";
import { sagaApiType } from "../../util/types";
import { call, put } from "redux-saga/effects";
import { API } from "../../../api";
import { userReducer } from "../../../store/reducers/user";
import { redirect } from "../../util/helpers/redirect";
import { routes } from "../../../routes";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import { errorsReducer } from "../../../store/auxiliary/errorsReducer";
import { sendMetrics } from "../../../services/yandexMetrika";
import { authSagaActions } from "../actions";

export function* verifyCodeSaga({
  payload,
  type: actionType,
}: PayloadAction<verifyCodeType>) {
  const process = processStateController(actionType);

  try {
    yield process.start();
    const response: sagaApiType = yield call(API.auth.verifyCode, payload);

    if (response.status === 204) {
      sendMetrics("new_loan");
      sendMetrics("common-client_vhod-v-sistemu_common-app");
    }

    yield put(
      userReducer.actions.setCode({
        code: payload.code,
      })
    );

    // чистим isFromSms тут, больше нам этот флаг не пригодится
    yield put(
      userReducer.actions.setIsFromSms({
        isFromSms: null,
      })
    );

    yield put(
      authSagaActions.createPassword({
        code: payload.code,
      })
    );

    // yield redirect(routes.auth.restore_password);
  } catch (e: any) {
    if (e?.response?.status == 404 || e?.response?.status == 400) {
      yield put(
        errorsReducer.actions.setFieldsErrors({
          code: "Неверный код!",
        })
      );
    } else {
      yield call(errorHandlerSaga, {
        response: e,
      });
    }
  } finally {
    yield process.stop();
  }
}
