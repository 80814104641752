import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { queryClient } from "./services/react_query";
import { store, history } from "./store";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-MDLJ9X7H",
  dataLayer: {
    userProject: "Creddy",
  },
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
      <ReactQueryDevtools
        position={"bottom-right"}
        toggleButtonProps={{
          style:
            process.env.REACT_APP_ENVIRONMENT === "prod"
              ? { display: "none" }
              : undefined,
        }}
      />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
