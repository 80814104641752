import NumberFormat, { NumberFormatProps } from "react-number-format";
import { Controller, useWatch } from "react-hook-form";
import mergeRefs from "react-merge-refs";
import get from "lodash/get";
import cn from "classnames";
import styles from "../index.module.css";
import { TextInputPropsType } from "../types";
import { ConnectedForm } from "../../ConnectedForm";
import { InputWrapper } from "../InputWrapper";
import { Icons } from "../../../../assets/icons";
import { useToggle } from "../../../../hooks/logical/useToggle";
import { useEffect, useRef } from "react";
import { useBlurred } from "../../../../hooks/logical/useBlurred";

export const InputNumber = (
  props: Omit<TextInputPropsType, "control"> & {
    suffix?: string;
    thousandsGroupStyle?: "thousand";
    maxLength?: number;
    onBlur?: any;
    noNumericString?: boolean;
    decimalScale?: number;
    step?: string;
  } & NumberFormatProps<any>
) => {
  const {
    inputElementRef,
    variant,
    control,
    support,
    noNumericString,
    step,
    ...jsxAttrs
  } = props;

  // const [errorMassage, setErrorMassage] = useState("");

  let errorMassage = "";

  // if (!props.validInn && props.name === "inn") {
  //   errorMassage = "Введите верный ИНН";
  // } else if (props.innLength) {
  //   errorMassage = "ИНН должен содержать 12 цифр";
  // }

  return (
    <ConnectedForm>
      {({ control, formState: { errors } }) => (
        <InputWrapper
          {...props}
          error={
            props.error ||
            get(errors as any, `${props.name}.message`, `${errorMassage}`)
          }
        >
          <Controller
            render={({ field }) => (
              <NumberFormat
                {...jsxAttrs}
                className={cn(styles.Input, {
                  [styles.Input_error]: props.error,
                  [styles.Input_large]: variant === "large",
                  [styles.Input_disabled]: jsxAttrs.disabled,
                })}
                getInputRef={mergeRefs([field.ref, props.inputElementRef])}
                {...field}
                thousandsGroupStyle={props.thousandsGroupStyle}
                allowNegative={false}
                step={step}
                placeholder={"0"}
                mask={""}
                suffix={props.suffix}
                format={props.format}
                decimalScale={
                  props.decimalScale === undefined ? 2 : props.decimalScale
                }
                onPaste={props.onPaste}
                thousandSeparator={props.thousandsGroupStyle ? " " : undefined}
                isNumericString={!props.noNumericString}
                maxLength={props.maxLength || 11}
                minLength={props.minLength || 11}
                onBlur={props.onBlur}
              />
            )}
            defaultValue={props.defaultValue}
            name={props.name}
            control={control}
          />
        </InputWrapper>
      )}
    </ConnectedForm>
  );
};
