import React, { useEffect, useRef, useState } from "react";

const isNumber = (value: string) => {
  return (Number(value) > 0 && Number(value) <= 9) || value == "0";
};

type useCodeFieldType = {
  codeLength: number;
  onChange: (value: string) => void;
  onFocus?: () => void;
  fieldsArray?: Array<string>;
  defaultValue?: string;
};

export const useCodeFieldLogic = (props: useCodeFieldType) => {
  const { codeLength, onChange, defaultValue } = props;

  const [retryCount, setRetryCount] = useState(1)
  const [tempInput, setTempInput] = useState(0);
  const [counter, setCounter] = useState(0);
  const [fieldsArray, setFieldsArray] = useState<Array<string>>(
    defaultValue ? defaultValue.split("") : Array(codeLength).fill("")
  );
  const arrayOfInputs = useRef<Array<HTMLInputElement | null>>([]);
  const focus = (index: number) => {
    arrayOfInputs.current[index]?.focus();
  };

  /* methods */
  const pasteHandler = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.clipboardData.getData("Text");
    const valueLength = value.length;
    const tempArray: Array<string> = fieldsArray;
    let isValid = true;

    for (let i = 0; i < valueLength && i < codeLength; i++) {
      tempArray[i] = value[i];
      if (!isNumber(value[i])) isValid = false;
    }
    const newIndex =
      valueLength < codeLength - 1 ? valueLength : codeLength - 1;

    if (isValid) {
      setTempInput(newIndex);
      focus(newIndex);
      setFieldsArray(tempArray.slice(0, codeLength));
    }
  };

  const setFieldsArraySetter = (newIndex: number, value: string) => {
    setFieldsArray(
      fieldsArray.map((item, index) => {
        return index == newIndex ? value : item;
      })
    );
  };

  const keyPressHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {

    const eventType = {
      delete: event.key == "Backspace",
    };
    if (eventType.delete) {
      if (!!fieldsArray[tempInput]) {
        setFieldsArraySetter(tempInput, "");
      } else if (tempInput != 0) {
        setFieldsArraySetter(tempInput - 1, "");
        focus(tempInput - 1);
        setTempInput(tempInput - 1);
      }

      if (counter > 0) setCounter(counter - 1);
    }
  };

  const onChangeHandler = (event: any) => {
    const value = event.nativeEvent.data;

    if (!isNumber(value)) {
      return;
    }

    const eventType = {
      moveIfIsFilled: Boolean(
        tempInput != codeLength - 1 && fieldsArray[tempInput]
      ),
      moveIfIsEmpty: !fieldsArray[tempInput],
    };

    if (eventType.moveIfIsFilled) {
      setFieldsArraySetter(tempInput + 1, value);

      setCounter(counter + 1);
      setTempInput(tempInput + 1);
      focus(tempInput + 1);
    }
    if (eventType.moveIfIsEmpty) {
      setFieldsArraySetter(tempInput, value);

      if (tempInput != codeLength - 1) {
        setTempInput(tempInput + 1);
        focus(tempInput + 1);
      }
      if (counter != codeLength) setCounter(counter + 1);
    }
  };

  const onClickHandler = (
    event: React.MouseEvent<HTMLInputElement>,
    value: number
  ) => {
    focus(fieldsArray.join("").length);
    event.currentTarget.setAttribute("placeholder", "");
    setTempInput(fieldsArray.join("").length);
    setFieldsArray(
      fieldsArray.map((item, index) => {
        return index >= value ? "" : item;
      })
    );
  };

  const [renderCounter, setRenderCounter] = useState(0)



  /* effects */
  useEffect(() => {
    arrayOfInputs.current = arrayOfInputs.current.slice(0, codeLength);
  }, []);
  useEffect(() => {
    if (renderCounter) {
      onChange(fieldsArray.join(""));
    }
  }, [fieldsArray]);

  // Твердый и чистый код
  useEffect(() => {
    setRenderCounter(renderCounter + 1)

    if (props.fieldsArray) {
      /** обрезаем итоговый массив полей чтобы копипастом нельзя было вставить лишние символы */
      setFieldsArray(props.fieldsArray.slice(0, codeLength));
    }
  }, [props.fieldsArray]);

  return {
    tempInput,
    fieldsArray,
    // fieldsArray: props.fieldsArray,
    arrayOfInputs,
    setFieldsArray,
    onClickHandler,
    pasteHandler,
    keyPressHandler,
    onChange: props.onChange,
    onFocus: props.onFocus,
    onChangeHandler,
    value: fieldsArray.join(""),
    retryCount,
    setRetryCount,
  };
};
