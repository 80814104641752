import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Picker from "react-mobile-picker";
import { errorsReducer, errorsSelectors } from "../../../store/auxiliary/errorsReducer";

type PropsType = {
  name: string;
  values: Array<any>;
  defaultValue: string;
};

export function TimePicker({ name, values, defaultValue }: PropsType) {
  const [startTouchPosition, setStartTouchPosition] = useState<number | undefined>(undefined);
  const [pickerValue, setPickerValue] = useState<{ value: string }>({
    value: defaultValue === "9" ? "09" : defaultValue
  });

  const dispatch = useDispatch();

  const { setValue } = useFormContext();

  const errors = useSelector(errorsSelectors.fieldsErrors);

  const touchScrollHandler = (event: React.TouchEvent<HTMLDivElement>) => {
    const currentValueIdx = values.indexOf(pickerValue.value);

    if (event.changedTouches[0].screenY > startTouchPosition! + 36) {
      if (currentValueIdx === 0) return;

      setStartTouchPosition(event.changedTouches[0].screenY);
      setPickerValue({ value: values[currentValueIdx - 1] });
    }

    if (event.changedTouches[0].screenY < startTouchPosition! - 36) {
      if (currentValueIdx === values.length - 1) return;

      setStartTouchPosition(event.changedTouches[0].screenY);
      setPickerValue({ value: values[currentValueIdx + 1] });
    }
  };

  useEffect(() => {
    setValue(name, pickerValue);

    if (errors) {
      dispatch(errorsReducer.actions.removeFieldError("date"));
    }
  }, [pickerValue]);

  useEffect(() => {
    setValue(name, { value: defaultValue });
  }, []);

  useEffect(() => {
      setPickerValue({
        value: defaultValue === "9"
          ? "09"
          : defaultValue === "0"
            ? "00"
            : defaultValue
      });
  }, [defaultValue]);

  return (
    <Picker
      height={140}
      itemHeight={43}
      wheelMode={"natural"}
      value={pickerValue}
      onChange={setPickerValue}
      onTouchMove={touchScrollHandler}
      onTouchStart={(e) => setStartTouchPosition(e.changedTouches[0].screenY)}
    >
      <Picker.Column name={"value"}>
        {values.map((option: any) => (
          <Picker.Item key={option} value={option}>
            {({ selected }) => (
              <div style={{
                padding: 500,
                fontSize: selected ? 46 : 32,
                color: selected ? "black" : "#E8EBF1",
                transition: "all 0.25s ease 0s"
              }}>
                {option}
              </div>
            )}
          </Picker.Item>
        ))}
      </Picker.Column>
    </Picker>
  );
}
