import React, { FC, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useWatch } from "react-hook-form";
import { isMobile } from "react-device-detect";
import { Icons } from "../../../../../assets/icons";
import styles from "./index.module.css";
import { Button } from "../../../../ui/Button";
import { applicationSagaActions } from "../../../../../sagas/application/actions";
import { applicationReducerSelectors } from "../../../../../store/reducers/application";
import { processLoading } from "../../../../../store/auxiliary/loadingReducer";
import { SmartForm } from "../../../../hocs/SmartForm";
import { DocumentsArea } from "../../../../ui/DocumentsArea";
import { documentsSchema } from "./schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { Loader } from "../../../../ui/Loader";
import { useGetInfoFromApplication } from "../../../../../hooks/api/useGetInfoFromApplication";
import { MobilePageContainer } from "../../../../ui/Containers/MobilePageContainer";
import { MotivationalPopup } from "../../../../ui/MotivationalPopup";
import { BannerWhite } from "../../../../ui/BannerWhite";
import { Images } from "../../../../../assets/img";
import {
  checkPhotoRecognition,
  sendMetrics,
} from "../../../../../services/yandexMetrika";
import { FillingAssistance } from "../../../../ui/FillingAssistance";
import { API } from "../../../../../api";
import { useHistory } from "react-router-dom";
import { routes } from "../../../../../routes";
import { call } from "redux-saga/effects";
import { errorHandlerSaga } from "../../../../../sagas/util/helpers/errorHandlerSaga";
import cn from "classnames";
import { lsController } from "../../../../../store/auxiliary/localStorage";

export const getDocumentInfo = (documents: any[], name: string) => {
  return documents?.find((document) => document.photo_type === name);
};
export const getDocumentInfoByID = (documents: any[], id?: string) => {
  return documents?.find((document) => document.id === id);
};

export const Documents: FC = () => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = React.useState(1);

  const [loadingFront, setLoadingFront] = React.useState(false);
  const [loadingBack, setLoadingBack] = React.useState(false);

  const nextPage = () => {
    dispatch(
      applicationSagaActions.sendingDocumentsForRecognize({
        applicationId,
      })
    );
    sendMetrics("foto_passport_ok");
  };

  const isMobileFromDeviceDetect: boolean =
    process.env.NODE_ENV === "development" ? true : isMobile;

  const dispatch = useDispatch();

  const form = useForm({
    reValidateMode: "onSubmit",
    resolver: yupResolver(documentsSchema),
  });
  const loading = useSelector(
    processLoading.get(applicationSagaActions.sendingDocumentsForRecognize.type)
  );
  const loading2 = useSelector(
    processLoading.get(applicationSagaActions.sendAllDocs.type)
  );
  const applicationId = useSelector(
    applicationReducerSelectors.getApplicationId
  );
  const applicationDocuments = useSelector(
    applicationReducerSelectors.getApplicationDocuments
  );

  const ptsMatch = useWatch({
    control: form.control,
    name: "pts_match",
  });
  // const forma = useWatch({
  //   control: form.control,
  // });
  //
  // console.log("forma", forma);
  // console.log("ERRS", form.formState.errors);

  const send = () => {
    if (application_type === "passport_photo_filling") {
      dispatch(applicationSagaActions.sendAllDocs());
    } else {
      dispatch(applicationSagaActions.sendPtsAndStsDocumentsSaga());
    }
  };

  const applicationType = useSelector(
    applicationReducerSelectors.getApplicationType
  );

  // * NEW
  const submit = (data: any) => {
    lsController.set("is_elpts", elpts);
    dispatch(
      applicationSagaActions.sendingDocumentsForRecognize({
        applicationId,
      })
    );
    const filtered = documents
      ?.filter((item: any) => item.photo_type !== "selfie_with_passport")
      .filter((item: any) => item.photo_type !== "recognized_document_photo");
    if (filtered.length === 0) {
      sendMetrics("foto_passport_ok");
    }
  };

  const { documents, car, isLoading, delayPing, application_type } =
    useGetInfoFromApplication(2000);

  const onUploaded = (photoType: string) => {
    delayPing(photoType);
  };

  const hasRecognationErrorDriver = documents?.some(
    (doc: any) =>
      doc.type === "recognized_document_photo" &&
      (doc.photo_type === "driver_license_front" ||
        doc.photo_type === "driver_license_back")
  );

  if (documents?.length) {
    // @ts-ignore
    checkPhotoRecognition(documents);
  }

  const documentsWithoutOptional = documents.filter(
    (item) =>
      item.photo_type !== "optional_passport_main" &&
      item.photo_type !== "optional_passport_registration"
  );

  const hasRecognationError =
    documentsWithoutOptional &&
    !!documentsWithoutOptional.filter(
      (doc: any) =>
        doc.type === "recognition_error_document_photo" ||
        (doc.type === "document_photo" &&
          doc.photo_type !== "selfie_with_passport")
    ).length;

  useEffect(() => {
    if (car) {
      form.setValue("pts_match", !car.is_elpts);
    }
  }, [car]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const elpts = car?.pts?.is_elpts;

  const disabledButton =
    (!car?.pts?.is_elpts &&
      ((documentsWithoutOptional?.length > 7
        ? documentsWithoutOptional?.length < 9
        : documentsWithoutOptional?.length < 7) ||
        hasRecognationError)) ||
    (car?.pts?.is_elpts &&
      ((documentsWithoutOptional?.length > 5
        ? documentsWithoutOptional?.length < 7
        : documentsWithoutOptional?.length < 5) ||
        hasRecognationError));

  const passportDocumentsValidation = () => {
    return documents?.length < 3;
  };

  const passportDocumentsValidationForFilling = () => {
    return documents?.length < 2;
  };

  const hasRecognitionError = documents?.some((document: any) => {
    const isRecognitionErrorPhoto =
      document?.type === "recognition_error_document_photo";
    const isDocumentPhoto =
      document?.type === "document_photo" &&
      (document.photo_type === "passport_main" ||
        document.photo_type === "passport_registration");
    const isSelfieWithPassport =
      document?.type === "selfie_with_passport" && isRecognitionErrorPhoto;
    const isRecognitionErrorPhotoPassport =
      isRecognitionErrorPhoto &&
      (document.photo_type === "passport_main" ||
        document.photo_type === "passport_registration");

    return (
      isRecognitionErrorPhotoPassport || isDocumentPhoto || isSelfieWithPassport
    );
  });

  const hasRecognitionErrorOption = documents?.some((document: any) => {
    const isRecognitionErrorPhoto =
      document?.type === "recognition_error_document_photo";
    const isDocumentPhoto =
      document?.type === "document_photo" &&
      (document.photo_type === "optional_passport_main" ||
        document.photo_type === "optional_passport_registration");
    const isRecognitionErrorPhotoPassport =
      isRecognitionErrorPhoto &&
      (document.photo_type === "optional_passport_main" ||
        document.photo_type === "optional_passport_registration");

    return isRecognitionErrorPhotoPassport || isDocumentPhoto;
  });

  // useEffect(() => {
  //   const photoTypes = [
  //     "passport_main",
  //     "passport_registration",
  //     "selfie_with_passport",
  //   ];
  //
  //   const isAllPhotosUploadedAndRecognized = photoTypes.every((photoType) => {
  //     const document = getDocumentInfo(applicationDocuments, photoType);
  //     return document && document.type !== "recognition_error_document_photo";
  //   });
  //
  //   // Если все три фотографии загружены и распознаны, переключаемся на вторую страницу
  //   if (isAllPhotosUploadedAndRecognized) {
  //     setCurrentPage(2);
  //   } else {
  //     setCurrentPage(1);
  //   }
  // }, [applicationDocuments]);

  useEffect(() => {
    currentPage === 2 && elpts && sendMetrics("foto_documents_epts");
  }, [currentPage]);

  // useEffect(() => {
  //   if (application_type === "passport_photo_filling") {
  //     sendMetrics("common-client_odobrenie-tv_common-app");
  //   } else if (application_type === "car_document_filling") {
  //     sendMetrics("common-client_stranica-foto-pts_common-app");
  //   }
  // }, []);

  useMemo(() => {
    if (application_type === "passport_photo_filling") {
      sendMetrics("common-client_odobrenie-tv_common-app");
    } else if (application_type === "car_document_filling") {
      sendMetrics("common-client_stranica-foto-pts_common-app");
    }
  }, [application_type]);

  useEffect(() => {
    sendMetrics("foto_documents_start");
  }, []);

  if (application_type === "refusal_final") {
    history.push(routes.application.statuses.error_final);
  }

  return (
    <>
      <MobilePageContainer className={styles.mobile_page_container}>
        <MotivationalPopup />
        {isLoading || !documents || !applicationId || !application_type ? (
          <Loader
            variant={"large"}
            color={"purple"}
            className={styles.loader}
          />
        ) : (
          <>
            {process.env.REACT_APP_ENVIRONMENT == "stage" && (
              <>
                {/*  * OLD, FOR TEST AND AUTO ATTACHING ALL DOCS TO APPLICATION */}
                <div className={styles.container}>
                  <Button onClick={send} loading={loading2}>
                    Отправить тестовые документы
                  </Button>
                </div>
              </>
            )}

            {/*<Button*/}
            {/*  className={styles.button_test}*/}
            {/*  onClick={() =>*/}
            {/*    setVariant((prev) =>*/}
            {/*      prev === "variant1" ? "variant2" : "variant1"*/}
            {/*    )*/}
            {/*  }*/}
            {/*  loading={loading}*/}
            {/*  type="button"*/}
            {/*>*/}
            {/*  Switch variant*/}
            {/*</Button>*/}
            {/*{application_type !== "car_document_filling" &&*/}
            {/*  application_type !== "passport_filling" &&*/}
            {/*  variant === "variant1" && (*/}
            {/*    <div className={styles.title}>Заявка одобрена</div>*/}
            {/*  )}*/}
            {/*{application_type !== "car_document_filling" && (*/}
            {/*  <div className={styles.main_title}>*/}
            {/*    По закону нам нужны фото актуальных документов. Просим Вас*/}
            {/*    сделать фото, а не загрузить с телефона{" "}*/}
            {/*    <div className={styles.main_subtitle}>*/}
            {/*      Фото экрана не принимаются*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*)}*/}
            {application_type === "passport_photo_filling" && (
              <>
                <div className={styles.title_car}>Заявка одобрена</div>
                <div className={styles.main_title}>
                  По закону нам нужны фото актуальных документов. Просим Вас
                  сделать фото, а не загрузить с телефона{" "}
                  <div className={styles.main_subtitle}>
                    Фото экрана не принимаются
                  </div>
                </div>
              </>
            )}
            {application_type === "car_document_filling" && (
              <>
                <div className={styles.title_car}>Заявка одобрена</div>
                <div className={styles.main_title_car}>
                  Для заключения договора и выдачи средств сделайте фото СТС
                  {!elpts && ", ПТС"} и автомобиля
                </div>
              </>
            )}
            {application_type === "repledge_document_filling" && (
              <>
                <div className={styles.title_car}>Заявка одобрена</div>
                <div className={styles.main_title_car}>
                  Паспорт принят, теперь документы по займу
                </div>
                <div className={styles.main_subtitle}>
                  Фото экрана не принимаются
                </div>
              </>
            )}
            {application_type === "passport_filling" && (
              <>
                <div className={styles.main_title}>
                  Загрузите фото, и мы автоматически <br /> заполним данные
                </div>
              </>
            )}
            {/*<BannerWhite />*/}

            {/*<div className={styles.info_block}>*/}
            {/*  <div className={styles.info_block_icon}>*/}
            {/*    <Icons.ui.CheckmarkGreen />*/}
            {/*  </div>*/}
            {/*  <div className={styles.info_block_text}>*/}
            {/*    <div>Состоим в реестре ЦБ РФ</div>*/}
            {/*    <div>*/}
            {/*      Хранилище данных соответствует требованиям 152-ФЗ и имеет*/}
            {/*      сертификат безопасности ФСТЭК*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*      Мы берём документы по требованию законодательства и исключаем*/}
            {/*      выдачу займа другому человеку*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className={styles.info}>*/}
            {/*  По закону (115-ФЗ) мы обязаны просить у вас <br /> фото*/}
            {/*  документов. Этим также мы исключаем <br /> выдачу займа другому*/}
            {/*  человеку.*/}
            {/*</div>*/}
            {/*  * NEW */}
            {isMobileFromDeviceDetect ? (
              <SmartForm form={form} submit={submit}>
                <>
                  {application_type === "passport_photo_filling" && (
                    <>
                      <div className={styles.row}>
                        <div className={styles.document_container}>
                          <div className={styles.documents_title}>
                            Первый разворот
                          </div>
                          <DocumentsArea
                            onUploaded={onUploaded}
                            className={styles.ground_files}
                            name="files.passport_main"
                            fixedFiles={["Первый разворот"]}
                            document_type={"passport_main"}
                            applicationId={applicationId}
                            uploadedDocument={getDocumentInfo(
                              documents,
                              "passport_main"
                            )}
                            placeholderImage={
                              Images.documentPhotoPlaceholders.passportMain
                            }
                          />
                        </div>
                        <div className={styles.document_container}>
                          <div className={styles.documents_title}>Прописка</div>
                          <DocumentsArea
                            onUploaded={onUploaded}
                            className={styles.ground_files}
                            name="files.passport_registration"
                            fixedFiles={["Прописка"]}
                            document_type={"passport_registration"}
                            applicationId={applicationId}
                            uploadedDocument={getDocumentInfo(
                              documents,
                              "passport_registration"
                            )}
                            placeholderImage={
                              Images.documentPhotoPlaceholders
                                .passportRegistration
                            }
                          />
                        </div>
                      </div>

                      <div className={styles.documents_title}>
                        Сэлфи с паспортом
                      </div>

                      <DocumentsArea
                        onUploaded={onUploaded}
                        className={styles.ground_files}
                        name="files.selfie_with_passport"
                        fixedFiles={["Селфи с паспортом"]}
                        document_type={"selfie_with_passport"}
                        applicationId={applicationId}
                        uploadedDocument={getDocumentInfo(
                          documents,
                          "selfie_with_passport"
                        )}
                        placeholderImage={
                          Images.documentPhotoPlaceholders.selfieWithPassport
                        }
                      />

                      <Button
                        className={cn(styles.button, {})}
                        disabled={
                          passportDocumentsValidation() ||
                          hasRecognitionError ||
                          typeof hasRecognitionError === "undefined"
                        }
                        onClick={nextPage}
                        loading={loading}
                        type="button"
                      >
                        Продолжить
                      </Button>
                    </>
                  )}
                </>

                <>
                  {application_type === "passport_filling" && (
                    <>
                      <div className={styles.row}>
                        <div className={styles.document_container}>
                          <div className={styles.documents_title}>
                            Первый разворот
                          </div>
                          <DocumentsArea
                            onUploaded={onUploaded}
                            className={styles.ground_files}
                            name="files.optional_passport_main"
                            fixedFiles={["Первый разворот"]}
                            document_type={"optional_passport_main"}
                            applicationId={applicationId}
                            uploadedDocument={getDocumentInfo(
                              documents,
                              "optional_passport_main"
                            )}
                            placeholderImage={
                              Images.documentPhotoPlaceholders.passportMain
                            }
                          />
                        </div>
                        <div className={styles.document_container}>
                          <div className={styles.documents_title}>Прописка</div>
                          <DocumentsArea
                            onUploaded={onUploaded}
                            className={styles.ground_files}
                            name="files.optional_passport_registration"
                            fixedFiles={["Прописка"]}
                            document_type={"optional_passport_registration"}
                            applicationId={applicationId}
                            uploadedDocument={getDocumentInfo(
                              documents,
                              "optional_passport_registration"
                            )}
                            placeholderImage={
                              Images.documentPhotoPlaceholders
                                .passportRegistration
                            }
                          />
                        </div>
                      </div>

                      <Button
                        className={cn(styles.button_variant_2, {})}
                        disabled={
                          passportDocumentsValidationForFilling() ||
                          hasRecognitionErrorOption ||
                          typeof hasRecognitionErrorOption === "undefined"
                        }
                        onClick={() => {
                          lsController.set("wasOptionalPhotoRecognized", true);
                          history.push(routes.application.passport_and_inn);
                        }}
                        loading={loading}
                        type="button"
                      >
                        Продолжить
                      </Button>

                      <div
                        className={styles.link_lc}
                        onClick={() =>
                          history.push(routes.application.passport_and_inn)
                        }
                      >
                        <Icons.ui.LinkToPC className={styles.img} />
                        <div className={styles.link_text}>
                          Вернуться к ручному заполнению
                        </div>
                      </div>
                    </>
                  )}
                </>

                {application_type === "repledge_document_filling" && (
                  <>
                    <div className={styles.row}>
                      <div className={styles.document_container}>
                        <div className={styles.documents_title}>
                          Справка о текущей задолженности
                        </div>
                        <DocumentsArea
                          onUploaded={onUploaded}
                          className={styles.ground_files}
                          name={"files.repledge_certificate_of_debt_status"}
                          fixedFiles={["Справка о текущей задолженности"]}
                          document_type={"repledge_certificate_of_debt_status"}
                          applicationId={applicationId}
                          uploadedDocument={getDocumentInfo(
                            documents,
                            "repledge_certificate_of_debt_statu"
                          )}
                          placeholderImage={
                            Images.documentPhotoPlaceholders.debtCertificate
                          }
                        />
                      </div>
                      {/*<div className={styles.document_container}>*/}
                      {/*  <div className={styles.documents_title}>Прописка</div>*/}
                      {/*  <DocumentsArea*/}
                      {/*    onUploaded={onUploaded}*/}
                      {/*    className={styles.ground_files}*/}
                      {/*    name="files.passport_registration"*/}
                      {/*    fixedFiles={["Прописка"]}*/}
                      {/*    document_type={"passport_registration"}*/}
                      {/*    applicationId={applicationId}*/}
                      {/*    uploadedDocument={getDocumentInfo(*/}
                      {/*      documents,*/}
                      {/*      "passport_registration"*/}
                      {/*    )}*/}
                      {/*    placeholderImage={*/}
                      {/*      Images.documentPhotoPlaceholders*/}
                      {/*        .passportRegistration*/}
                      {/*    }*/}
                      {/*  />*/}
                      {/*</div>*/}
                    </div>

                    <div className={styles.documents_title}>
                      Сэлфи с паспортом
                    </div>

                    <DocumentsArea
                      onUploaded={onUploaded}
                      className={styles.ground_files}
                      name={"files.repledge_loan_contract"}
                      fixedFiles={["Селфи с паспортом"]}
                      document_type={"repledge_loan_contract"}
                      applicationId={applicationId}
                      uploadedDocument={getDocumentInfo(
                        documents,
                        "repledge_loan_contract"
                      )}
                      placeholderImage={
                        Images.documentPhotoPlaceholders.selfieWithPassport
                      }
                    />

                    <Button
                      className={cn(styles.button, {})}
                      disabled={
                        passportDocumentsValidation() ||
                        hasRecognitionError ||
                        typeof hasRecognitionError === "undefined"
                      }
                      onClick={nextPage}
                      loading={loading}
                      type="button"
                    >
                      Продолжить
                    </Button>
                  </>
                )}

                {application_type === "car_document_filling" && (
                  <>
                    {!elpts && (
                      <>
                        {ptsMatch && ptsMatch !== undefined && (
                          <>
                            <div className={styles.row}>
                              <div className={styles.document_container}>
                                <div className={styles.documents_title}>
                                  ПТС Внутренняя сторона
                                </div>
                                <DocumentsArea
                                  onUploaded={onUploaded}
                                  className={styles.ground_files}
                                  name="files.pts_back"
                                  fixedFiles={["ПТС 2 сторона"]}
                                  document_type={"pts_back"}
                                  applicationId={applicationId}
                                  uploadedDocument={getDocumentInfo(
                                    documents,
                                    "pts_back"
                                  )}
                                  placeholderImage={
                                    Images.documentPhotoPlaceholders
                                      .ptsBackDocument
                                  }
                                />
                              </div>
                              <div className={styles.document_container}>
                                <div className={styles.documents_title}>
                                  ПТС Внешняя сторона
                                </div>
                                <DocumentsArea
                                  onUploaded={onUploaded}
                                  className={styles.ground_files}
                                  name="files.pts_front"
                                  fixedFiles={["ПТС 1 сторона"]}
                                  document_type={"pts_front"}
                                  applicationId={applicationId}
                                  uploadedDocument={getDocumentInfo(
                                    documents,
                                    "pts_front"
                                  )}
                                  placeholderImage={
                                    Images.documentPhotoPlaceholders
                                      .ptsFrontDocument
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}

                    <div className={styles.row}>
                      <div className={styles.document_container}>
                        <div className={styles.documents_title}>
                          СТС данные авто
                        </div>
                        <DocumentsArea
                          onUploaded={onUploaded}
                          className={styles.ground_files}
                          name="files.vehicle_registration_certificate_front"
                          fixedFiles={["СТС с данными автомобиля"]}
                          document_type={
                            "vehicle_registration_certificate_front"
                          }
                          applicationId={applicationId}
                          uploadedDocument={getDocumentInfo(
                            documents,
                            "vehicle_registration_certificate_front"
                          )}
                          placeholderImage={
                            Images.documentPhotoPlaceholders.stsVehicleData
                          }
                        />
                      </div>
                      <div className={styles.document_container}>
                        <div className={styles.documents_title}>
                          СТС данные владельца
                        </div>
                        <DocumentsArea
                          onUploaded={onUploaded}
                          className={styles.ground_files}
                          name="files.vehicle_registration_certificate_back"
                          fixedFiles={["СТС с данными собственника"]}
                          document_type={
                            "vehicle_registration_certificate_back"
                          }
                          applicationId={applicationId}
                          uploadedDocument={getDocumentInfo(
                            documents,
                            "vehicle_registration_certificate_back"
                          )}
                          placeholderImage={
                            Images.documentPhotoPlaceholders.stsOwnerInformation
                          }
                        />
                      </div>
                    </div>

                    {/*<div className={styles.info_block_license}>*/}
                    {/*  <div className={styles.info_block_icon_license}>*/}
                    {/*    <Icons.ui.Exclamation />*/}
                    {/*  </div>*/}
                    {/*  <div className={styles.info_block_text_license}>*/}
                    {/*    <div>*/}
                    {/*      Фото ВУ не обязательно, <br />*/}
                    {/*      но{" "}*/}
                    {/*      <span className={styles.bold}>*/}
                    {/*        увеличивает вероятность одобрения*/}
                    {/*      </span>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                    {/*<div className={styles.row}>*/}
                    {/*  <div className={styles.document_container}>*/}
                    {/*    <div className={styles.documents_title_gray}>*/}
                    {/*      ВУ сторона с фото*/}
                    {/*    </div>*/}
                    {/*    <DocumentsArea*/}
                    {/*      onUploaded={(photoType) => {*/}
                    {/*        setLoadingFront(true);*/}
                    {/*        onUploaded(photoType);*/}
                    {/*      }}*/}
                    {/*      className={styles.ground_file_opacity}*/}
                    {/*      name="files.driver_license_front"*/}
                    {/*      fixedFiles={["1 сторона"]}*/}
                    {/*      document_type={"driver_license_front"}*/}
                    {/*      applicationId={applicationId}*/}
                    {/*      uploadedDocument={getDocumentInfo(*/}
                    {/*        documents,*/}
                    {/*        "driver_license_front"*/}
                    {/*      )}*/}
                    {/*      placeholderImage={*/}
                    {/*        Images.documentPhotoPlaceholders.licenseFront*/}
                    {/*      }*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*  <div className={styles.document_container}>*/}
                    {/*    <div className={styles.documents_title_gray}>*/}
                    {/*      ВУ сторона с данными*/}
                    {/*    </div>*/}
                    {/*    <DocumentsArea*/}
                    {/*      onUploaded={(photoType) => {*/}
                    {/*        setLoadingBack(true);*/}
                    {/*        onUploaded(photoType);*/}
                    {/*      }}*/}
                    {/*      className={styles.ground_file_opacity}*/}
                    {/*      name="files.driver_license_back"*/}
                    {/*      fixedFiles={["2 сторона"]}*/}
                    {/*      document_type={"driver_license_back"}*/}
                    {/*      applicationId={applicationId}*/}
                    {/*      uploadedDocument={getDocumentInfo(*/}
                    {/*        documents,*/}
                    {/*        "driver_license_back"*/}
                    {/*      )}*/}
                    {/*      placeholderImage={*/}
                    {/*        Images.documentPhotoPlaceholders.licenseBack*/}
                    {/*      }*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    <Button
                      loading={loading}
                      disabled={
                        disabledButton ||
                        (!hasRecognationErrorDriver &&
                          (loadingFront || loadingBack))
                      }
                    >
                      Отправить фото
                    </Button>
                  </>
                )}
              </SmartForm>
            ) : (
              <div>Откройте заявку на телефоне</div>
            )}
          </>
        )}
      </MobilePageContainer>
      {/*<FillingAssistance className={styles.footer} />*/}
    </>
  );
};
