import React, { FC } from "react";
import styles from "./index.module.css";
import { ApplicationHeader } from "./components/Header";
import { Route } from "react-router-dom";
import { NewLoan } from "./pages/NewLoan";
import { Documents } from "./pages/Documents";
import { routes } from "../../../routes";
import { PassportAndInn } from "./pages/PassportAndInn";
import { CarPhoto } from "./pages/CarPhoto";
import { LoanDecision } from "./pages/LoanDecision";
import { LoanProcessing } from "./pages/LoanProcessing";
import { ApplicationStatusRoutes } from "./pages/Statuses";
import { Code } from "./pages/Code";
import { AccountConfirmation } from "./pages/AccountConfirmation";
import { SBPDefineProcess } from "./pages/SPBDefineProcess";
import { RecognizeDocuments } from "./pages/Statuses/RecognizeDocuments";
import { LoanRejection } from "./pages/LoanRejection";
import { PartnerApplication } from "./pages/PartnerApplication";
import { VideoCallPlanned } from "./pages/VideoCallPlanned";
import { FioAndGrz } from "./pages/FioAndGrz";
import { RepeatedPhotos } from "./pages/RepeatedPhotos";
import { Refinancing } from "./pages/Refinancing";
import { RepledgePhoto } from "./pages/ReplegePhoto";

export const Application: FC = () => {
  return (
    <div className={styles.container}>
      <ApplicationHeader />

      <Route path={routes.application.new_loan}>
        <NewLoan />
      </Route>

      <Route path={routes.application.refinancing}>
        <Refinancing />
      </Route>

      <Route path={routes.application.partner_application}>
        <PartnerApplication />
      </Route>

      <Route path={routes.application.video_call_planned}>
        <VideoCallPlanned />
      </Route>

      <Route path={routes.application.loan_rejection}>
        <LoanRejection />
      </Route>

      <Route path={routes.application.documents}>
        <Documents />
      </Route>

      <Route path={routes.application.repeated_photos}>
        <RepeatedPhotos />
      </Route>

      <Route path={routes.application.passport_and_inn}>
        <PassportAndInn />
      </Route>

      <Route path={routes.application.fio_and_grz}>
        <FioAndGrz />
      </Route>

      <Route path={routes.application.car_photo}>
        <CarPhoto />
      </Route>

      <Route path={routes.application.replege_photo}>
        <RepledgePhoto />
      </Route>

      <Route path={routes.application.code.path}>
        <Code />
      </Route>

      <Route path={routes.application.loan_decision}>
        <LoanDecision />
      </Route>

      <Route path={routes.application.loan_processing}>
        <LoanProcessing />
      </Route>

      <Route path={routes.application.account_confirmation}>
        <AccountConfirmation />
      </Route>

      <Route path={routes.application.sbp_bank_filling}>
        <SBPDefineProcess />
      </Route>

      <ApplicationStatusRoutes />
    </div>
  );
};
