import React, { FC } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import styles from "./index.module.css";
import { routes } from "../../../routes";
import { Header } from "./components/Header";
import { Title } from "../../ui/Title";
import { Subtitle } from "../../ui/Subtitle";
import { RestorePassword } from "./pages/RestorePassword";
import { Password } from "./pages/Password";
import { useSelector } from "react-redux";
import { StateType } from "../../../store";
import { useMedia } from "hooks/logical/useMedia";
import { ToMobileDevice } from "./pages/ToMobileDevice";
import { Info } from "../Info";
import cn from "classnames";
import { VehicleValuation } from "./pages/VehicleValuation";
import { CarCheckingPhone } from "../Application/pages/CarCheckingPhone";
import { CarCheckingCode } from "../Application/pages/CarCheckingCode";
import { AuthHeader } from "./components/AuthHeader";
import { WrongVehicle } from "./pages/WrongVehicle";
import { VehicleError } from "./pages/VehicleError";
import { VehicleNotFound } from "./pages/VehicleNotFound";
import { PersonalCabinet } from "./pages/PersonalCabinet";
import { PersonalCabinetPassword } from "./pages/PersonalCabinetPassword";
import { PhoneEditable } from "./components/PhoneEditable";
import { UserDeviceInfo } from "../Application/pages/UserDeviceInfo";

export const Auth: FC = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  const phoneNumber = useSelector(
    (state: StateType) => state.userReducer.phone
  );

  const authPageTitles = {
    // [`${routes.auth.phone}`]: "Зарегистрируйтесь!",
    // [`${routes.auth.password}`]: "Войти в Creddy",
    // [`${routes.auth.vehicle_valuation}`]: (
    //   <>
    //     <div className={styles.title_vehicle}>
    //       Онлайн-займ <br />
    //       под залог авто{" "}
    //     </div>
    //   </>
    // ),
    // [`${routes.auth.code}/restore`]: "Подтверждение",
    // [`${routes.auth.code}/register`]: "Подтверждение",
    // [`${routes.auth.restore_password}`]: "Регистрация",
    [`${routes.auth.to_mobile_device}`]: "Войти в Creddy",
  };

  const codeSubtitle = (
    <>
      <div className={styles.subTitle}>
        Введите код подтверждения,
        <br />
        отправленный на номер: <br />
      </div>
      {phoneNumber && (
        <PhoneEditable
          phoneNumber={phoneNumber}
          onClick={() => history.push(routes.auth.phone)}
        />
      )}
    </>
  );

  const toMobileSubtitle = (
    <div className={styles.mobile_subtitle_wrapper}>
      Подача заявки происходит на мобильном устройстве
      <br />
      Для перехода к оформлению займа, мы отправим вам
      <br />
      ссылку в СМС.
    </div>
  );

  const authPageSubtitles = {
    // [`${routes.auth.phone}`]: "Введите номер телефона, привязанный к СБП",
    // [`${routes.auth.password}`]:
    //   "Введите пароль, который вы устанавливали для входа в личный кабинет.",
    // [`${routes.auth.code}/restore`]: codeSubtitle,
    // [`${routes.auth.code}/register`]: codeSubtitle,
    // [`${routes.auth.restore_password}`]: "Придумайте и введите пароль",
    [`${routes.auth.to_mobile_device}`]: toMobileSubtitle,
  };

  const { width } = useMedia();

  return (
    <div
      className={cn(styles.container, {
        [styles.container_main]:
          pathname !== routes.auth.vehicle_valuation &&
          pathname !== routes.auth.personal_cabinet &&
          pathname !== routes.auth.personal_cabinet_password,
      })}
    >
      {width < 1023 &&
        (pathname === routes.auth.vehicle_valuation ||
          pathname === routes.auth.personal_cabinet ||
          pathname === routes.auth.personal_cabinet_password) && (
          <Header width={width} />
        )}
      <div
        className={cn(styles.content_container, {
          [styles.content_container_main]:
            pathname !== routes.auth.vehicle_valuation &&
            pathname !== routes.auth.personal_cabinet &&
            pathname !== routes.auth.personal_cabinet_password,
        })}
      >
        {width < 1023 &&
          pathname !== routes.auth.vehicle_valuation &&
          pathname !== routes.auth.personal_cabinet &&
          pathname !== routes.auth.personal_cabinet_password && <AuthHeader />}
        {width >= 1023 && pathname === routes.auth.phone && (
          <Header width={width} />
        )}
        {/*{pathname === routes.auth.phone && <div className={styles.step}>1</div>}*/}
        <Title
          className={cn(styles.title, {
            [styles.title_phone]: pathname === routes.auth.phone,
          })}
        >
          {authPageTitles[pathname]}
        </Title>
        <Subtitle className={styles.subtitle}>
          {authPageSubtitles[pathname]}
        </Subtitle>

        <Route path={routes.auth.to_mobile_device}>
          <ToMobileDevice />
        </Route>

        <Route path={routes.auth.phone}>
          <CarCheckingPhone width={width} />
          {/*<Phone width={width} />*/}
        </Route>

        <Route path={routes.auth.password}>
          <Password />
        </Route>

        <Route path={routes.auth.code + "/:codeMode"}>
          <CarCheckingCode width={width} />
          {/*<Code />*/}
        </Route>

        <Route path={routes.auth.restore_password}>
          <RestorePassword />
        </Route>

        <Route path={routes.info}>
          <Info />
        </Route>

        <Route path={routes.auth.vehicle_error}>
          <VehicleError />
        </Route>

        <Route path={routes.auth.vehicle_not_found}>
          <VehicleNotFound />
        </Route>

        <Route path={routes.auth.vehicle_valuation}>
          <VehicleValuation width={width} />
        </Route>

        <Route path={routes.auth.wrong_vehicle}>
          <WrongVehicle />
        </Route>

        <Route path={routes.auth.personal_cabinet}>
          <PersonalCabinet />
        </Route>

        <Route path={routes.auth.personal_cabinet_password}>
          <PersonalCabinetPassword />
        </Route>

        <Route path={routes.auth.device_info}>
          <UserDeviceInfo />
        </Route>

        {/*{width >= 1023 && pathname === routes.auth.phone && (*/}
        {/*  <footer className={styles.footer_container}>*/}
        {/*    <div className={styles.footer_flex}>*/}
        {/*      <div className={styles.footer_title}>*/}
        {/*        Полное фирменное наименование: Общество с ограниченной*/}
        {/*        ответственностью Микрофинансовая компания «Кредди». Сокращенное*/}
        {/*        фирменное наименование: ООО МФК «Кредди»*/}
        {/*      </div>*/}
        {/*      <div className={styles.strip}></div>*/}
        {/*      <div className={styles.footer_title}>*/}
        {/*        ИНН: 9710037450 ОГРН: 5177746022262*/}
        {/*      </div>*/}
        {/*      <div className={styles.strip}></div>*/}
        {/*      <div className={styles.footer_title}>*/}
        {/*        Ссылка на страницу Банка России, содержащую государственный*/}
        {/*        реестр микрофинансовых организаций:{" "}*/}
        {/*        <a*/}
        {/*          href="https://cbr.ru/microfinance/registry"*/}
        {/*          target={"_blank"}*/}
        {/*        >*/}
        {/*          https://cbr.ru/microfinance/registry*/}
        {/*        </a>*/}
        {/*      </div>*/}
        {/*      <div className={styles.strip}></div>*/}
        {/*      <div className={styles.footer_title}>*/}
        {/*        Регистрационный номер записи в государственном реестре*/}
        {/*        микрофинансовых организаций: 1703045008644 от 01.12.2017*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </footer>*/}
        {/*)}*/}
      </div>

      {/*<div className={styles.right_image_part}>*/}
      {/*  <img src={manInCar} alt={"mainLogo"} />*/}
      {/*  /!*<div className={styles.image_title}>*!/*/}
      {/*  <Icons.logos.CreddyLarge className={styles.creddy_logo} />*/}
      {/*  /!*  Современная <br /> и технологичная <br /> компания*!/*/}
      {/*  /!*</div>*!/*/}
      {/*</div>*/}

      {/* {![routes.auth.phone].includes(pathname)
    &&
    <div className={styles.desktop_gradient}>
      <Triangle className={styles.triangle_1} />
      <Triangle className={styles.triangle_2} />
    </div>} */}

      {/* Тут экран со свинюшкой */}
      {/* {false
    &&
    <div className={styles.desktop_gradient}>
      <Triangle className={styles.triangle_1} />
      <Triangle className={styles.triangle_2} />
    </div>} */}
    </div>
  );
};
