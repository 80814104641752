import styles from "./index.module.css";
import React, { FC, useEffect, useRef, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import cn from "classnames";
import { InputNumber } from "../Inputs/InputNumber";

var timeoutId = 123;

type propsType = {
  disabled?: boolean;
  timeout?: number;
  retryCount: number;
  sendCodeHandler?(): void;
  setRetryCount: React.Dispatch<React.SetStateAction<number>>;
};

const spaceResolver = (code: string | undefined) => {
  if (!code) return;

  let defaultSpacing = 32;

  for (let i = 0; i < code.length - 1; i++) {
    if (code[i] === "1") {
      if (i === 2) {
        defaultSpacing += 2;
      } else {
        defaultSpacing += 1;
      }
    } else if (code[i] === "7") {
      defaultSpacing += 0.5;
    }
  }

  return Math.floor(defaultSpacing);
};

export const CodeFieldOneInput: FC<propsType> = ({
  disabled,
  timeout = 60,
  retryCount,
  setRetryCount,
  sendCodeHandler,
}) => {
  const [timer, setTimer] = useState(timeout);

  const form = useFormContext();
  // const ref = useRef<HTMLInputElement>();

  const code: `${number}` | undefined = useWatch({
    control: form.control,
    name: "code",
  });

  function createTimer() {
    window.clearInterval(timeoutId);
    timeoutId = window.setInterval(updateTimer, 1000);
  }

  function updateTimer() {
    setTimer((timer) => {
      if (timer <= 0) {
        window.clearInterval(timeoutId);
        return 0;
      } else {
        return timer - 1;
      }
    });
  }

  // useEffect(() => {
  //   if (code?.length === 4) {
  //     ref.current?.blur();
  //   }
  // }, [code]);

  useEffect(() => {
    timeoutId = window.setInterval(updateTimer, 1000);
  }, []);

  return (
    <>
      <InputNumber
        style={{ letterSpacing: spaceResolver(code) }}
        className={styles.input}
        name={"code"}
        maxLength={4}
        minLength={4}
        autoFocus
        // inputElementRef={ref}
      />
      <div className={styles.backlight}>
        <div className={cn(styles.backlight_item, {
          [styles.highlighted]: code?.length && code.length >= 1,
          // [styles.small_margin]: code![0] === "1",
        })} />
        <div className={cn(styles.backlight_item, {
          [styles.highlighted]: code?.length && code.length >= 2,
          // [styles.small_margin]: code![1] === "1",
        })} />
        <div className={cn(styles.backlight_item, {
          [styles.highlighted]: code?.length && code.length >= 3,
          // [styles.small_margin]: code![2] === "1",
        })} />
        <div className={cn(styles.backlight_item, {
          [styles.highlighted]: code?.length && code.length >= 4,
          // [styles.small_margin]: code![3] === "1",
        })} />
      </div>
      <div className={styles.timer}>
        {timer >= -1 && (
          <>
            <div
              onClick={() => {
                if (timer == 0 && retryCount < 5) {
                  setTimer(timeout);
                  setRetryCount(retryCount + 1);
                  createTimer();
                }
              }}
              className={cn(styles.timer_text, {
                [styles["resend_code--disabled"]]: retryCount == 5,
              })}
            >
              <div className={cn(styles.repeat_title, {
                [styles.disabled]: disabled,
              })}>
                {" "}
                {timer > 0 ? (
                  <>
                    Запросить код еще раз <br /> через{" "}
                    <span>
                      {timer >= 60
                        ? "01:" + (Math.floor(timer) - 60 + "0")
                        : "00:" +
                        (timer >= 10
                          ? Math.floor(timer)
                          : "0" + Math.floor(timer))}
                    </span>
                  </>
                ) : (
                  <div
                    className={cn(styles.resend_code, {
                      [styles.disabled]: disabled,
                    })}
                    onClick={sendCodeHandler}
                  >
                    {/*Повторно отправить код {sendCodeCounter} / 5*/}
                    Отправить еще раз
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
};
