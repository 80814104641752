import React, { useState } from "react";
import styles from "./index.module.css";
import cn from "classnames";
import { TextInputPropsType } from "../Inputs/types";
import { useFormContext, useWatch } from "react-hook-form";

// * PROPS
// * innerLabels - массив из 2 string, текст внутри switcher'a
type propsType<T> = {
  huge?: boolean;
  falseStatus?: string;
  trueStatus?: string;
  switchVariant?: "default" | "middle" | "large" | "huge" | "huge_gender";
  innerLabels?: [string, string];
  outerLabel?: string;
} & Omit<TextInputPropsType, "control">;

export function Switcher<T>({
  huge,
  switchVariant,
  innerLabels,
  outerLabel,
  ...props
}: propsType<T>) {
  const { control, setValue, getValues } = useFormContext();
  const value = useWatch({
    control,
    name: props.name,
  });

  const inputProps = control.register(props.name);

  // Возможно стоит переписать на useWatch, но пока работает
  const currentValue = getValues(inputProps.name);

  return (
    <div>
      {outerLabel && <div className={styles.outer_label}>{outerLabel}</div>}
      <label
        className={cn(props.className, {
          [styles.container]: true,
          [styles.huge]: huge,
          [styles[`${switchVariant}`]]: switchVariant !== "default",
          [styles[`${props.variant}`]]: props.variant !== "default",
          [styles.checked]: value,
          [styles.disabled]: props.disabled
        })}
      >
        <input
          className={styles.switch_input}
          type="checkbox"
          value={props.value}
          defaultChecked={props.defaultChecked}
          {...inputProps}
          onChange={(e) => {
            inputProps.onChange(e);
            setValue(inputProps.name, !value);
            props.onChange && props.onChange(e);
          }}
          disabled={props.disabled}
        />
        <div className={styles.circle_container}>
          {innerLabels && (
            <>
              <div
                className={cn(styles.innerLabel, {
                  [styles.innerLabel_active]: !value,
                })}
              >
                {innerLabels?.[0]}
              </div>
              <div
                className={cn(styles.innerLabel, {
                  [styles.innerLabel_active]: value,
                })}
              >
                {innerLabels?.[1]}
              </div>
            </>
          )}
          <div className={styles.circle} />
        </div>
        {/*{props.trueStatus && props.falseStatus && (*/}
        {/*  <div className={styles.text}>*/}
        {/*    <div className={styles.top_text}>{props.trueStatus}</div>*/}
        {/*    <div className={styles.bottom_text}>{props.falseStatus}</div>*/}
        {/*  </div>*/}
        {/*)}*/}
      </label>
    </div>
  );
}
