import React, { FC, useState } from "react";
import styles from "./index.module.css";
import { Icons } from "assets/icons";
import { MobilePageContainer } from "../../../../ui/Containers/MobilePageContainer";
import { FillingAssistance } from "../../../../ui/FillingAssistance";
import { CarInformation } from "../../../../ui/CarInformation";
import { Button } from "../../../../ui/Button";
import { SmartForm } from "../../../../hocs/SmartForm";
import { routes } from "../../../../../routes";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { applicationReducerSelectors } from "../../../../../store/reducers/application";
import { useQuery } from "react-query";
import { API } from "../../../../../api";
import { applicationType } from "../../../../../types/application/types";
import { Loader } from "../../../../ui/Loader";
import { LoaderPage } from "../../../../ui/LoaderPage";

export const LoanRejection: FC = () => {
  const history = useHistory();

  const applicationType = useSelector(
    applicationReducerSelectors.getRefuseReason
  );

  const applicationId = useSelector(
    applicationReducerSelectors.getApplicationId
  );

  const { data, isLoading } = useQuery(
    "code",
    () => API.main.application.getApplication({ applicationId }),
    {
      enabled: applicationId !== null,
      onSuccess: (data: any) => {
        const application: applicationType = data?.data?.data;
      },
    }
  );

  const application: applicationType = data?.data?.data;

  const refuseReason = application?.refuse_reason;

  const partnersHref = "https://bankipartners.ru/s/0ejVes4QdG?statid=1023_&erid=1";

  const resolveRejectionText = (refuseReason: string | null): string => {
    switch (refuseReason) {
      case "car_check_owner":
        return "Паспортные данные и данные владельца автомобиля не совпадают";
      case "car_check_pledge":
        return "Автомобиль находится в залоге";
      case "car_check_no_vin":
        return "У автомобиля отсутствует VIN";
      case "car_check_year":
        return "Ваш автомобиль не подходит";
      case "car_check_price":
        return "Ваш автомобиль не подходит по минимальной стоимости";
      default:
        return "";
    }
  };

  const rejectionText = resolveRejectionText(refuseReason);

  return (
    <MobilePageContainer className={styles.form}>
      {isLoading ? (
        <Loader className={styles.loading} variant={"large"}/>
      ) : (
        <>
          {" "}
          <div className={styles.container}>
            <div className={styles.title}>Мы не можем выдать вам займ</div>
            {isLoading ? (
              <Loader/>
            ) : (
              <div className={styles.text}>
                {rejectionText ? rejectionText : ""}
              </div>
            )}
            <div className={styles.loader}>
              <Icons.ui.CrossWarning/>
            </div>
          </div>
          <div className={styles.container_car_information}>
            {/*<div className={styles.name_title}>*/}
            {/*  Фиолетовый Константин Константинович*/}
            {/*</div>*/}
            {application?.refuse_reason === "car_check_owner" && (
              <>
                <div className={styles.car_title}>Данные в заявке:</div>
                <CarInformation
                  isCar={false}
                  firstName={application?.user_info?.first_name}
                  lastName={application?.user_info?.last_name}
                  middleName={application?.user_info?.middle_name}
                  birthDay={application?.user_info?.birth_day}
                  carLicensePlate={application?.car?.reg_number}
                  isLoading={isLoading}
                />
              </>
            )}
            <div className={styles.car_title}>Автомобиль:</div>
            <CarInformation
              carYear={application?.car?.year}
              carModel={application?.car?.name_original}
              carLicensePlate={application?.car?.reg_number}
              isLoading={isLoading}
            />
            {refuseReason === "car_check_owner" || refuseReason === "car_check_year" ? (
              <>
                <Button className={styles.button}>
                  <a href={partnersHref}>Подать заявку у партнёров</a>
                </Button>
                <a className={styles.link_lc} onClick={() => history.push(routes.application.fio_and_grz)}>
                  <nav className={styles.link_text}>Подать заявку с другим номером авто</nav>
                </a>
              </>
            ) : (
              <>
                <Button
                  className={styles.button}
                  onClick={() => history.push(routes.application.fio_and_grz)}
                >
                  Подать заявку с другим автомобилем
                </Button>
                <a className={styles.link_lc} href={"https://creddy.ru/"}>
                  <nav className={styles.link_text}>Закрыть заявку</nav>
                </a>
              </>
            )}
          </div>
          {/*<FillingAssistance />{" "}*/}
        </>
      )}
    </MobilePageContainer>
  );
};
