import React, { FC, useEffect, useState } from "react";
import styles from "./index.module.css";
import { MobilePageContainer } from "../../../../ui/Containers/MobilePageContainer";
import { Button } from "../../../../ui/Button";
import { SmartForm } from "../../../../hocs/SmartForm";
import { useForm, useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { processLoading } from "../../../../../store/auxiliary/loadingReducer";
import { applicationSagaActions } from "../../../../../sagas/application/actions";
import { applicationReducerSelectors } from "../../../../../store/reducers/application";
import { useGetInfoFromApplication } from "../../../../../hooks/api/useGetInfoFromApplication";
import {
  carPhotoType,
  DocumentApiTypes,
} from "../../../../../types/documents/types";
import { DocumentsArea } from "../../../../ui/DocumentsArea";
import { getCarPhotoInfo } from "../CarPhoto";
import { getDocumentInfo, getDocumentInfoByID } from "../Documents";
import { Images } from "../../../../../assets/img";
import { Loader } from "../../../../ui/Loader";

const photoCarTypeEnum = [
  "front_left",
  "front_right",
  "back_left",
  "back_right",
  "vin",
  "dashboard",
  "selfie_with_car",
  "interior",
];

const photoStatusResolver: {
  [key in string]: {
    title: string;
  };
} = {
  selfie_with_car: {
    title: "селфи с автомобилем",
  },
  vin: {
    title: "VIN",
  },
  back_left: {
    title: "сзади слева",
  },
  back_right: {
    title: "сзади справа",
  },
  dashboard: {
    title: "приборная панель",
  },
  front_left: {
    title: "спереди слева",
  },
  front_right: {
    title: "спереди справа",
  },
  interior: {
    title: "передняя часть салона",
  },
  passport_main: {
    title: "первый разворот",
  },
};

export const RepeatedPhotos: FC = () => {
  const history = useHistory();

  const isMobileFromDeviceDetect: boolean =
    process.env.NODE_ENV === "development" ? true : isMobile;

  const dispatch = useDispatch();

  const form = useForm({
    reValidateMode: "onSubmit",
    // resolver: yupResolver(replegePhotoSchema),
  });

  const loading = useSelector(
    processLoading.get(
      applicationSagaActions.sendingReplacedPhotoForRecognize.type
    )
  );

  const applicationId: number | string | null = useSelector(
    applicationReducerSelectors.getApplicationId
  );

  const {
    documents,
    car_photos,
    car,
    isLoading: isDocumentsLoading,
    delayPing,
    delayPingForRepeat,
    application_type,
    isPingEnabled,
    isDocumentsPhotoLoader,
  } = useGetInfoFromApplication(2000);

  const sortByNameDocumentsPhoto = documents
    .filter((elem) => {
      return (
        elem.type !== "replaced_document_photo" &&
        elem?.photo_type !== "optional_passport_registration" &&
        elem?.photo_type !== "optional_passport_main" &&
        elem?.photo_type !== "repledge_loan_contract"
      );
    })
    ?.sort((a: any, b) => a.photo_type.localeCompare(b.photo_type));

  const sortByNameRefDocuments = documents
    .filter((elem) => {
      return (
        elem?.photo_type === "repledge_loan_contract" &&
        elem.type !== "replaced_document_photo"
      );
    })
    ?.sort((a: any, b) => a.photo_type.localeCompare(b.photo_type));

  const sortByNameRefDocumentsLength = documents.filter((elem) => {
    return elem?.photo_type === "repledge_loan_contract";
  });

  const sortByNameCarPhoto = car_photos?.sort((a: any, b: any) =>
    a.photo_type.localeCompare(b.photo_type)
  );

  const AllDocumentsArrayToRender = sortByNameDocumentsPhoto
    ?.concat(car_photos)
    ?.concat(sortByNameRefDocuments);

  const conditionToDisabledButton = AllDocumentsArrayToRender.find((el) => {
    return el?.confirmed_by_underwriter === false;
  });

  const conditionToRenderPlusComponent = documents.find((el) => {
    return (
      el?.photo_type === "repledge_loan_contract" &&
      el.type === "replaced_document_photo"
    );
  });

  const conditionToRenderRefDocuments = documents.filter((el) => {
    return (
      el?.photo_type === "repledge_loan_contract" &&
      el.type !== "replaced_document_photo" &&
      !el?.confirmed_by_underwriter
    );
  });

  const [prevDocumentPhotoType, setPrevDocumentPhotoType] = useState<
    string | undefined
  >("");

  const onUploaded = (photoType: string) => {
    setPrevDocumentPhotoType(photoType);
    delayPingForRepeat(photoType);
  };

  const submit = () => {
    dispatch(
      applicationSagaActions.sendingReplacedPhotoForRecognize({
        applicationId,
      })
    );
  };

  const renderDocumentAreaRef = (
    isPlusIcon: boolean,
    key: number,
    name: string | JSX.Element,
    documentType: DocumentApiTypes.documentType | "repledge_loan_contract_add",
    fixedFiles: string[],
    placeholderImage: string,
    id?: string
  ) => (
    <div key={key} className={styles.document_container}>
      <div className={styles.documents_title}>{name}</div>
      <DocumentsArea
        applicationType={application_type}
        id={id}
        plusIcon={isPlusIcon}
        onUploaded={onUploaded}
        className={styles.ground_files}
        name={
          documentType === "repledge_loan_contract"
            ? `files.${documentType}.${id}`
            : `files.${documentType}`
        }
        fixedFiles={fixedFiles}
        document_type={documentType}
        applicationId={applicationId}
        uploadedDocument={getDocumentInfo(documents, documentType)}
        placeholderImage={placeholderImage}
        docs={sortByNameRefDocumentsLength}
      />
    </div>
  );

  const renderDocumentArea = (
    index: number,
    isPlusIcon: boolean,
    key: number,
    name: string | JSX.Element,
    documentType: DocumentApiTypes.documentType,
    fixedFiles: string[],
    placeholderImage: string,
    id?: string,
    underwriter_comment?: string,
    isConfirmedByUnderwriter?: boolean
  ) => {
    return (
      <div key={key} className={styles.document_container}>
        <div className={styles.divider} />
        <div className={styles.title}>
          Ваше фото {photoStatusResolver[documentType]?.title}:
        </div>
        <div className={styles.content}>
          <DocumentsArea
            id={id}
            isPhotoRepeat
            loadingDocuments={isDocumentsPhotoLoader}
            plusIcon={isPlusIcon}
            isConfirmedByUnderwriter={isConfirmedByUnderwriter}
            onUploaded={() => onUploaded(documentType)}
            className={styles.ground_files}
            name={`files.${documentType}.${id}`}
            fixedFiles={fixedFiles}
            document_type={documentType}
            applicationId={applicationId}
            uploadedDocument={
              photoCarTypeEnum.includes(documentType)
                ? getCarPhotoInfo(car_photos, documentType)
                : getDocumentInfoByID(documents, id)
            }
            placeholderImage={placeholderImage}
            docs={documents.concat(car_photos)}
          />

          <div className={styles.note}>
            <div className={styles.title}>Комментарий</div>
            <div className={styles.text}>{underwriter_comment}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <MobilePageContainer className={styles.container}>
        <SmartForm form={form} submit={submit}>
          {isDocumentsLoading || !documents || !applicationId ? (
            <Loader
              variant={"large"}
              color={"purple"}
              className={styles.loader}
            />
          ) : (
            <>
              <div className={styles.main_title}>Переделайте фото</div>
              <div>
                {Array.from(sortByNameCarPhoto, (elem: any, index) => {
                  if (!elem?.confirmed_by_underwriter) {
                    return renderDocumentArea(
                      index,
                      false,
                      elem.id,
                      "",
                      elem.photo_type as any,
                      [`${elem.photo_type}`],
                      Images.documentPhotoPlaceholders.backGroundGray,
                      elem.id,
                      elem.underwriter_comment,
                      elem?.confirmed_by_underwriter
                    );
                  }
                })}
              </div>

              {/*{sortByNameDocumentsPhoto && <div className={styles.divider} />}*/}

              <div>
                {Array.from(sortByNameDocumentsPhoto, (elem: any, index) => {
                  if (!elem?.confirmed_by_underwriter) {
                    return renderDocumentArea(
                      index,
                      false,
                      elem.id,
                      "",
                      elem.photo_type as any,
                      [`${elem.photo_type}`],
                      Images.documentPhotoPlaceholders.backGroundGray,
                      elem.id,
                      elem.underwriter_comment,
                      elem?.confirmed_by_underwriter
                    );
                  }
                })}

                {Boolean(conditionToRenderRefDocuments.length) && (
                  <>
                    <div className={styles.title_replege}>Договор займа</div>
                  </>
                )}

                {Array.from(sortByNameRefDocuments, (elem: any, index) => {
                  if (!elem?.confirmed_by_underwriter) {
                    return renderDocumentArea(
                      index,
                      false,
                      elem.id,
                      "",
                      elem.photo_type as any,
                      [`${elem.photo_type}`],
                      Images.documentPhotoPlaceholders.backGroundGray,
                      elem.id,
                      elem.underwriter_comment,
                      elem?.confirmed_by_underwriter
                    );
                  }
                })}

                {Boolean(conditionToRenderRefDocuments.length) && (
                  <div className={styles.divider} />
                )}

                {conditionToRenderPlusComponent &&
                  Boolean(conditionToRenderRefDocuments.length) &&
                  renderDocumentAreaRef(
                    false,
                    0,
                    "",
                    "repledge_loan_contract_add",
                    ["Договор займа"],
                    Images.documentPhotoPlaceholders.repledgeLoanContractAdd
                  )}
              </div>
              <Button
                loading={loading}
                className={styles.button}
                disabled={Boolean(conditionToDisabledButton)}
              >
                Отправить фото
              </Button>
            </>
          )}
        </SmartForm>
      </MobilePageContainer>
    </>
  );
};
