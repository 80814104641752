import { PayloadAction } from "@reduxjs/toolkit";
import { processStateController } from "../../util/helpers/processStateController";
import { addingPassportAndInnToApplicationType } from "../../../api/main/application/types";
import { call, put, select } from "redux-saga/effects";
import { API } from "../../../api";
import { redirect } from "../../util/helpers/redirect";
import { routes } from "../../../routes";
import {
  applicationReducer,
  applicationReducerSelectors,
} from "../../../store/reducers/application";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import { sagaApiType } from "../../util/types";
import { applicationType } from "../../../types/application/types";
import { sendMetrics } from "../../../services/yandexMetrika";

export function* addingPassportAndInnToApplicationSaga({
  payload,
  type: actionType,
}: PayloadAction<addingPassportAndInnToApplicationType>) {
  const process = processStateController(actionType);

  try {
    yield process.start();

    // const applicationId: number = yield select(
    //   applicationReducerSelectors.getApplicationId
    // );

    // POST /passport
    const response: sagaApiType = yield call(
      API.main.application.addingPassportAndInnToApplication,
      {
        applicationId: payload.applicationId,
        inn: payload.inn,
        passport: payload.passport,
        actual_address: payload.actual_address,
        registration_address: payload.registration_address,
        monthly_income: payload.monthly_income,
        trusted_person_phone: payload.trusted_person_phone,
      }
    );

    if (response.status === 201) {
      sendMetrics("check anketa");
      sendMetrics("common-client_otpravlen-pasport-v-ankete_common-app");
    }

    const application: applicationType = response?.data?.data;

    if (application.application_type === "refusal_final") {
      yield put(
        applicationReducer.actions.setError({
          error_message: application?.refusal_cause_comment,
        })
      );
      yield redirect(routes.application.statuses.data_verification);
      return;
    }

    yield redirect(routes.application.statuses.data_verification);
    // yield redirect(routes.application.replege_photo);
  } catch (e) {
    yield call(errorHandlerSaga, {
      response: e,
      processType: actionType,
      handledStatuses: [400],
    });
  } finally {
    yield process.stop();
  }
}
