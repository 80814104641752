import moment from "moment";

const months = [
  "января",
  "февраля",
  "марта",
  "апреля",
  "мая",
  "июня",
  "июля",
  "августа",
  "сентября",
  "октября",
  "ноября",
  "декабря",
] as const;

const timezones = [
  "KALT",
  "MSK",
  "SAMT",
  "YEKT",
  "OMST",
  "KRAT",
  "IRKT",
  "YAKT",
  "VLAT",
  "MAGT",
  "PETT",
] as const;

const monthsIdx = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] as const;
const timezonesIdx = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12] as const;

export const showDMYDate = (item: Date | string | undefined) => {
  if (!item) return "—";
  return `${
    new Date(item).getDate() > 9
      ? new Date(item).getDate()
      : "0" + new Date(item).getDate()
  }.${
    new Date(item).getMonth() > 8
      ? new Date(item).getMonth() + 1
      : "0" + (Number(new Date(item).getMonth()) + 1)
  }.${new Date(item).getFullYear()}`;
};
export const addZeroToNumber = (number: number | string) => {
  return Number(number) <= 9 ? "0" + number : number;
};

export const showYMDDate = (item: any) => {
  if (!item) return "—";
  return `${new Date(item).getFullYear()}-${
    new Date(item).getMonth() > 8
      ? new Date(item).getMonth() + 1
      : "0" + (new Date(item).getMonth() + 1)
  }-${
    new Date(item).getDate() > 9
      ? new Date(item).getDate()
      : "0" + new Date(item).getDate()
  }`;
};

export const dateMethods = {
  calculateTimeDifference: (timeFrom: Date | string, timeTo: Date | string) => {
    const t1 = moment(timeTo);
    const t2 = moment(timeFrom);
    const diff = t1.diff(t2);

    return diff / 3600000;
  },

  getTwoDatesByDateAndInterval: (dates: {
    date: string;
    date_from: string;
    duration: string;
  }) => {
    const date_from = moment(dates.date)
      .add(dates.date_from, "hours")
      .utcOffset("+0000")
      .format();
    const date_to = moment(date_from)
      .add(dates.duration, "minutes")
      .utcOffset("+0000")
      .format();

    return {
      date_from,
      date_to,
    };
  },

  getTime: (date: string | Date) => {
    return moment(date).format("HH:mm");
  },

  getTimeInterval: (date_from: string, date_to: string) => {
    return (
      moment(date_from).format("HH:mm") + "-" + moment(date_to).format("HH:mm")
    );
  },

  format: (date: string | Date | undefined, options?: string) => {
    if (!date) return "—";
    return moment(date).format(options);
  },

  format2: (date: string | null, reverse?: boolean): any => {
    if (reverse) {
      return date?.split("-").reverse().join(".");
    }
    return date?.split(".").reverse().join("-");
  },

  dateMinus18Years: () =>
    moment()
      .set("year", moment().get("year") - 21)
      .toDate(),

  dateMinus60Years: () =>
    moment()
      .set("year", moment().get("year") - 66)
      .toDate(),

  getFullDaysBetween: (dateFrom: Date, dateTo: Date): number => {
    const date1 = new Date(dateFrom);
    const date2 = new Date(dateTo);
    return Math.ceil((date2.getTime() - date1.getTime()) / 86400000);
  },

  getMonthName: (date: Date): typeof months[number] => {
    const numOfMonth = date.getMonth() as typeof monthsIdx[number];

    switch (numOfMonth) {
      case 0:
        return "января";
      case 1:
        return "февраля";
      case 2:
        return "марта";
      case 3:
        return "апреля";
      case 4:
        return "мая";
      case 5:
        return "июня";
      case 6:
        return "июля";
      case 7:
        return "августа";
      case 8:
        return "сентября";
      case 9:
        return "октября";
      case 10:
        return "ноября";
      case 11:
        return "декабря";
    }
  },

  getTimezone: (date: Date): typeof timezones[number] => {
    const timeOffset = Number(
      String(date).slice(20, 22)
    ) as typeof timezonesIdx[number];

    switch (timeOffset) {
      case 2:
        return "KALT";
      case 3:
        return "MSK";
      case 4:
        return "SAMT";
      case 5:
        return "YEKT";
      case 6:
        return "OMST";
      case 7:
        return "KRAT";
      case 8:
        return "IRKT";
      case 9:
        return "YAKT";
      case 10:
        return "VLAT";
      case 11:
        return "MAGT";
      case 12:
        return "PETT";
    }
  },

  getTimezoneCity: (date: Date, end?: "ое" | "ому"): string => {
    const timeOffset = Number(String(date).slice(20, 22));

    switch (timeOffset) {
      case 2:
        switch (end) {
          case "ое":
            return "Калининградское";
          case "ому":
            return "Калининградскому";
          default:
            return "Калининградская";
        }
      case 3:
        switch (end) {
          case "ое":
            return "Московское";
          case "ому":
            return "Московскому";
          default:
            return "Московская";
        }
      case 4:
        switch (end) {
          case "ое":
            return "Самарское";
          case "ому":
            return "Самарскому";
          default:
            return "Самарская";
        }
      case 5:
        switch (end) {
          case "ое":
            return "Екатеринбургское";
          case "ому":
            return "Екатеринбургскому";
          default:
            return "Екатеринбургская";
        }
      case 6:
        switch (end) {
          case "ое":
            return "Омское";
          case "ому":
            return "Омскому";
          default:
            return "Омская";
        }
      case 7:
        switch (end) {
          case "ое":
            return "Красноярское";
          case "ому":
            return "Красноярскому";
          default:
            return "Красноярская";
        }
      case 8:
        switch (end) {
          case "ое":
            return "Иркутское";
          case "ому":
            return "Иркутскому";
          default:
            return "Иркутская";
        }
      case 9:
        switch (end) {
          case "ое":
            return "Якутское";
          case "ому":
            return "Якутскому";
          default:
            return "Якутская";
        }
      case 10:
        switch (end) {
          case "ое":
            return "Владивостокское";
          case "ому":
            return "Владивостокскому";
          default:
            return "Владивостокская";
        }
      case 11:
        switch (end) {
          case "ое":
            return "Магаданское";
          case "ому":
            return "Магаданскому";
          default:
            return "Магаданская";
        }
      case 12:
        switch (end) {
          case "ое":
            return "Камчатское";
          case "ому":
            return "Камчатскому";
          default:
            return "Камчатская";
        }
      default:
        return "";
    }
  },
};

export const toServerDate = (
  date: string | Date,
  defender?: boolean,
  addTimezone?: boolean
): string => {
  if (defender && !date) {
    //@ts-ignore
    return undefined;
  }
  if (addTimezone) {
    return moment(date).format();
  }

  return moment(date).format("YYYY-MM-DD");
};
