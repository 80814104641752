import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";
import { errorHandlerSaga } from "../../util/helpers/errorHandlerSaga";
import { authSagaActions } from "../actions";
import { API } from "../../../api";
import { sagaApiType } from "../../util/types";
import { stringHelpers } from "../../../helpers/string";
import { userReducer } from "../../../store/reducers/user";
import { UserType } from "../../../types/user/types";
import { setProcessLoading } from "../../../store/auxiliary/loadingReducer";
import { isMobile } from "react-device-detect";
import { redirect } from "../../util/helpers/redirect";
import { routes } from "../../../routes";
import { lsController } from "../../../store/auxiliary/localStorage";
import { carType } from "../../../store/reducers/application/types";
import { useSelector } from "react-redux";
import { applicationReducerSelectors } from "../../../store/reducers/application";
import { sendMetrics } from "../../../services/yandexMetrika";

/*
  При ПЕРВОМ логине юзера с телефоном в квери параметре, коллится эта сага.
 */

export function* createUserSaga({
  payload,
}: PayloadAction<{ phone: string; car?: carType }>) {
  const actionType = authSagaActions.getUser.type;
  console.log("createUserSaga called");
  const grz = lsController.get("grz");
  const vin = lsController.get("vin");

  try {
    if (!isMobile) {
      yield redirect(routes.auth.to_mobile_device);
    }

    const response: sagaApiType = yield call(API.auth.create, payload);

    const user: UserType = response.data.data;

    yield put(
      userReducer.actions.setPhone({
        phone: stringHelpers.clearString(payload.phone),
      })
    );

    yield put(
      userReducer.actions.setId({
        user_id: user.id,
      })
    );

    yield put(
      userReducer.actions.setUserRole({
        user_role: user.user_role,
      })
    );

    if (payload.phone) {
      sendMetrics("common-client_registraciya_new-app");
      yield put(
        authSagaActions.getCode({
          smsTemplate: isMobile ? "register_mobile" : "register_desktop",
        })
      );
    } else {
      yield redirect(routes.auth.phone);
    }
  } catch (e) {
    yield call(errorHandlerSaga, {
      response: e,
    });
  } finally {
    yield put(setProcessLoading(actionType, false));
  }
}
