import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  applicationReducer,
  applicationReducerSelectors,
} from "../../../../../../store/reducers/application";
import { LoadingPage } from "../../LoadingPage";
import { useQuery } from "react-query";
import { API } from "../../../../../../api";
import { applicationType } from "../../../../../../types/application/types";
import { routes } from "../../../../../../routes";
import { sendMetrics } from "../../../../../../services/yandexMetrika";

type propsType = {};
export const IssueMethodFilling: FC<propsType> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const applicationId = useSelector(
    applicationReducerSelectors.getApplicationId
  );

  const query = useQuery(
    "IssueMethodFilling",
    () =>
      API.main.application.getApplication({
        applicationId,
      }),
    {
      refetchInterval: 10000,
      onSuccess: (data: any) => {
        const application: applicationType = data?.data?.data;
        dispatch(
          applicationReducer.actions.setApplicationType({
            application_type: application?.application_type,
          })
        );

        if (application?.application_type === "issue_method_payment_process") {
          history.push(routes.application.loan_processing);
        }

        if (application?.application_type === "survey_filling") {
          history.push(routes.application.sbp_bank_filling);
        }

        if (application?.application_type === "error_final") {
          dispatch(
            applicationReducer.actions.setError({
              error_message: application?.error_message,
            })
          );
          history.push(routes.application.statuses.error_final);
        }
      },
    }
  );

  return (
    <LoadingPage
      title={"Подождите, пожалуйста"}
      subtitle={"Обрабатываем фотографии"}
      countdownTime={300}
    />
  );
};
