export type photoType =
  | "passport_main"
  | "optional_passport_main"
  | "passport_registration"
  | "optional_passport_registration"
  | "pts_back"
  | "pts_front"
  | "vehicle_registration_certificate_back"
  | "vehicle_registration_certificate_front"
  | "selfie_with_passport"
  | "driver_license_front"
  | "driver_license_back"
  | "repledge_loan_contract"
  | "repledge_certificate_of_debt_status";

export const photoTypeData = [
  "passport_main",
  "optional_passport_main",
  "passport_registration",
  "optional_passport_registration",
  "pts_back",
  "pts_front",
  "vehicle_registration_certificate_back",
  "vehicle_registration_certificate_front",
  "selfie_with_passport",
  "driver_license_front",
  "driver_license_back",
  "repledge_loan_contract",
  "repledge_certificate_of_debt_status",
];

export type carPhotoType =
  | "front_right"
  | "front_left"
  | "back_right"
  | "back_left"
  | "vin"
  | "dashboard"
  | "selfie_with_car"
  | "interior";

export type documentPhotoType =
  | "draft_photo"
  | "document_photo"
  | "recognized_document_photo"
  | "recognition_error_document_photo"
  | "car_photo"
  | "replaced_car_photo"
  | "replaced_document_photo";

export type loanDocsType =
  | "applying_agreement"
  | "applying_agreement_second"
  | "check_fio_report"
  | "check_vehicle_report"
  | "deposit_contract"
  | "loan_contract"
  | "personal_data_agreement"
  | "pre_acceptance"
  | "risk_notification";

export type loanDocumentsType = {
  id: `${number}`;
  created_at: Date;
  document_type: loanDocsType;
  file_name: string;
  file_path: string;
  type: "application_document";
};

export type passportMainType = {
  id: number;
  file_name: string;
  type: documentPhotoType;
  document_type: photoType;
  fields: passportMainFieldsType;
};

export type passportRegistrationType = {
  fields: passportRegistrationFieldsType;
} & Omit<passportMainType, "fields">;

export type passportMainFieldsType = Record<
  | "apartment_number"
  | "date_of_birth"
  | "date_of_issue"
  | "first_name"
  | "inn"
  | "mrz_1"
  | "mrz_2"
  | "other_names"
  | "place_of_birth"
  | "series_and_number"
  | "sex"
  | "subdivision_code"
  | "surname"
  | "issuing_authority",
  fieldsObject
>;

export type passportRegistrationFieldsType = Record<
  | "address"
  | "apartment"
  | "building"
  | "date"
  | "house"
  | "locality"
  | "locality_district"
  | "region"
  | "region_district"
  | "street"
  | "subdivision_code",
  fieldsObject
>;

type fieldsObject = {
  value: string | null;
  confidence: number | null;
};

// * TEST

export type ApplicationTypesType =
  | "application"
  | "credit-line"
  | "factoring-application"
  | "factoring-carrier-application";

export namespace DocumentTypes {
  export type entity = {
    // * OLD
    application_id: string;
    created_at: string;
    document_type: DocumentApiTypes.documentType;
    files: Array<DocumentApiTypes.FileServerType>;
    id: string;

    // * NEW
    confirmed_by_underwriter?: boolean;
    underwriter_comment?: string;
    file_name?: string;
    photo_type?: photoType;
    type?: documentPhotoType;
  };
}

export namespace DocumentApiTypes {
  export type uploadCustomFile = {
    content: string;
    file_name: string;
  };
  export type getCustomFile = {
    id: string;
  };

  export type documentType =
    // * СТРАНИЦА ФОТОК ПАСПОРТА
    | "passport_main"
    | "optional_passport_main"
    | "passport_registration"
    | "optional_passport_registration"
    | "selfie_with_passport"
    | "pts_front"
    | "pts_back"
    | "vehicle_registration_certificate_front"
    | "vehicle_registration_certificate_back"
    | "driver_license_front"
    | "driver_license_back"

    // * СТРАНИЦА ФОТОК РЕФИНАНСИРОВАНИЯ
    // | "repledge_certificate_of_debt_status"
    // | "repledge_loan_contract"

    // * СТРАНИЦА ФОТОК МАШИНЫ
    | "front_left"
    | "front_right"
    | "back_left"
    | "back_right"
    | "vin"
    | "dashboard"
    | "selfie_with_car"
    | "interior"

    // * СТРАНИЦА Replege
    | "repledge_certificate_of_debt_status"
    | "repledge_loan_contract"
    | "repledge_loan_contract_add";

  export type documentTypeField = {
    document_type: documentType;
  };
  export type documentId = {
    document_id: string;
  };
  export type fileId = {
    file_id: string;
  };
  export type FileServerType = {
    // * OLD
    // id: string;
    // document_id: string;
    // file_name: string;
    // original_file_name: string;
    // mime_type: string;
    // document_type?: any;

    //  * NEW
    file_name: string;
    id: number;
    photo_type: photoType;
    type: documentPhotoType;
  };

  /* DOCUMENTS */
  // post
  export type createDocument = documentTypeField & {
    files: Array<string>;
  };
  export type createFile = {
    content: string;
    file_name: string;
  } & Partial<documentId> &
    documentTypeField;
  // get
  export type getDocument = documentTypeField;

  // delete
  export type deleteFile = documentId & fileId;

  export type assignFileToDocumentType = {
    file_id: string;
  } & documentId &
    documentTypeField;
}
