import { createFC } from "helpers/createFC";
import styles from "./index.module.css";
import React, { HTMLAttributes, SVGProps, useEffect, useRef } from "react";
import cn from "classnames";
import { Icons } from "assets/icons";
import { InputEditable } from "../Inputs/InputEditable";
import { useToggle } from "../../../hooks/logical/useToggle";

type propsType = {
  icon: SVGProps<SVGElement>;
  isLoading?: boolean;
  loadingMessage?: string;
} & HTMLAttributes<HTMLDivElement>;

/*
  Кейс с ошибкой (с красным фоном) будет тогда, когда будет ошибка.
 */

export const MessageBox = createFC(
  ({ children, className, icon, isLoading, loadingMessage }: propsType) => {
    const [isEditing, toggleEditing] = useToggle();
    const ref = useRef<any>();

    useEffect(() => {
      if (isEditing) {
        ref.current.focus();
      }
    }, [isEditing]);

    return (
      <div className={cn(styles.container, className)}>
        {isLoading ? (
          <div>
            <Icons.ui.MessageBoxLoading className={styles.loader} />
          </div>
        ) : (
          <div className={styles.icon}>{icon}</div>
        )}
        <InputEditable name={"passport.issued_by"} label={"Кем выдан"} />
        {/*<div className={styles.message_text}>*/}
        {/*  {isLoading ? loadingMessage : children}*/}
        {/*</div>*/}

        {/*<Icons.ui.PencilBlack />*/}
      </div>
    );
  }
);
