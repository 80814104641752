import React, { FC, useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AxiosError } from "axios";
import { lsController } from "../../store/auxiliary/localStorage";
import { API } from "../../api";
import { authSagaActions } from "../../sagas/auth/actions";
import {
  applicationReducer,
  applicationReducerSelectors,
} from "../../store/reducers/application";
import { routes } from "../../routes";
import { sendMetrics } from "../../services/yandexMetrika";
import { StateType } from "../../store";
import { carBodyType } from "../../store/reducers/application/types";
import { sagaActions } from "../../sagas/util/sagaActions";
import { loanReducerSelectors } from "../../store/reducers/loan";

export const useFetchCar = (
  // reg_number: string,
  // vin: string,
  phoneNumber: string | undefined,
  history: any,
  setIsLoadingFetchCar: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const dispatch = useDispatch();

  const lastStatus: string | null | undefined = useSelector(
    applicationReducerSelectors.getLastStatus
  );

  const itemsCount: number | null | undefined = useSelector(
    applicationReducerSelectors.getItemsCount
  );

  const onlyErrorLoans: boolean = useSelector(
    loanReducerSelectors.getOnlyErrorLoans
  );

  const phone = lsController.get("phone");

  const userId = useSelector((state: StateType) => state.userReducer.user_id);

  // const user_id = lsController.get("user_id");
  const refusalStatusApplicationOrPaidLoan =
    itemsCount === 0 || lastStatus === "paid_loan" || onlyErrorLoans;

  const fetchCar = (vinOrRegNumber: string) => {
    setIsLoadingFetchCar(true);
    API.main.services
      // .getCar(reg_number || reg_number?.length > 0 ? { reg_number } : { vin })
      .getCar(
        vinOrRegNumber.length > 9
          ? { vin: vinOrRegNumber }
          : { reg_number: vinOrRegNumber }
      )
      .then((res: any) => {
        if (res?.data?.data?.is_valid && res.status === 200) {
          sendMetrics("check auto");
          if (phoneNumber && !refusalStatusApplicationOrPaidLoan) {
            // dispatch(
            //   authSagaActions.getUser({
            //     phone: phoneNumber,
            //   })
            // );
          }
          dispatch(
            applicationReducer.actions.setCarBody({
              car_body: res?.data?.data,
            })
          );

          lsController.set("cost", res.data.data.cost);
          lsController.set("loan_amount", res.data.data.loan_amount);

          if (refusalStatusApplicationOrPaidLoan && phone) {
            dispatch(sagaActions.application.createApplicationShort());
          } else if (!userId && !refusalStatusApplicationOrPaidLoan) {
            history.push(routes.auth.phone);
          }

          setIsLoadingFetchCar(false);
        } else if (res.status === 204) {
          setTimeout(() => fetchCar(vinOrRegNumber), 2000);
        } else {
          sendMetrics("check auto reject");
          dispatch(
            applicationReducer.actions.setCarBody({
              car_body: res?.data?.data,
            })
          );
          history.push(routes.auth.wrong_vehicle);
          setIsLoadingFetchCar(false);
        }
      })
      .catch((e: AxiosError) => {
        setIsLoadingFetchCar(false);
        lsController.clearGRZ();
        lsController.clearVIN();
        history.push(
          e.response?.status === 400
            ? routes.auth.vehicle_not_found
            : routes.auth.vehicle_error
        );
      });
  };

  return fetchCar;
};
