import React, { FC, useState } from "react";
import { LoadingPage } from "../../LoadingPage";
import { useDispatch, useSelector } from "react-redux";
import {
  applicationReducer,
  applicationReducerSelectors,
} from "../../../../../../store/reducers/application";
import { useQuery } from "react-query";
import { API } from "../../../../../../api";
import { Loader } from "../../../../../ui/Loader";
import { Icons } from "assets/icons";
import { useHistory } from "react-router-dom";
import { Button } from "../../../../../ui/Button";
import { inspect } from "util";
import styles from "./index.module.css";
import { applicationType } from "../../../../../../types/application/types";
import { MobilePageContainer } from "../../../../../ui/Containers/MobilePageContainer";
import { routes } from "../../../../../../routes";
import { stringHelpers } from "../../../../../../helpers/string";
import { loanReducer } from "../../../../../../store/reducers/loan";
import { lsController } from "../../../../../../store/auxiliary/localStorage";

export type transferType = "natural_person" | "legal_person" | null;

export const IssuedFinal: FC = () => {
  const dispatch = useDispatch();
  const application_id = useSelector(
    applicationReducerSelectors.getApplicationId
  );

  const history = useHistory();

  // *  на этой страницце есть 2 варианта внешнего вида в зависимости от приходящих данных с бека
  const { data, isLoading } = useQuery(
    "IssuedFinal",
    () =>
      API.main.application.getApplication({
        applicationId: application_id || lsController.get("application_id"),
      }),
    {
      onSuccess: (data: any) => {
        const application: applicationType = data?.data?.data;
        dispatch(
          applicationReducer.actions.setApplicationType({
            application_type: application.application_type,
          })
        );
      },
    }
  );

  const application: applicationType = data?.data?.data;

  const transferType: transferType =
    application?.repledge_external_loan_data?.transfer_type;

  const isRepledge = application?.repledge;

  const variant = "card"; // выплата на контакт или на карту "card". Бека нет исчо

  const [isGetApplicationLoading, setIsGetApplicationLoading] = useState(false);
  const toLk = () => {
    setIsGetApplicationLoading(true);
    API.main.application
      .getLoan({})
      .then((res) => {
        if (res.status === 200) {
          setIsGetApplicationLoading(false);
          const loan = res?.data?.data?.items[0];
          // const loan = res?.data?.items?.[0];
          console.log(loan);
          dispatch(
            loanReducer.actions.setLoanId({
              loan_id: loan?.id,
            })
          );
          history.push(routes.cabinet.my_loan);
        }
      })
      .catch((e) => {
        console.log("getLoan error", e);
      });
  };

  const cardSubtitle = (
    <>
      Сумма{" "}
      <span className={styles.bold}>
        {stringHelpers.transformMoneyValue(
          application?.selected_loan_data?.amount
        )}
      </span>{" "}
      ₽ зачислена на ваш счет
    </>
  );
  const contactSubtitle = (
    <>
      Сумма <span className={styles.bold}>800 000</span> ₽ деньги могут быть{" "}
      <br /> выданы в
      <a href="#" className={styles.link}>
        офисах Contact
      </a>
    </>
  );
  const repledgeSubtitle = (
    <div className={styles.container_repledge_subtitle}>
      <div>
        <span className={styles.bold}>
          {application?.repledge_external_loan_data?.amount_to_pay_for_loan &&
            stringHelpers.transformMoneyValue(
              application?.selected_loan_data?.amount -
                application?.repledge_external_loan_data?.amount_to_pay_for_loan
            )}{" "}
          ₽
        </span>{" "}
        на Ваш счет в СБП в банке
        <br />
        <span>{application?.bank_card?.bank}</span>
      </div>
      <br />
      <div>
        <span className={styles.bold}>
          {stringHelpers.transformMoneyValue(
            application?.repledge_external_loan_data?.amount_to_pay_for_loan
          )}{" "}
          ₽
        </span>{" "}
        на погашение займа в <br />
        {transferType === "legal_person"
          ? application?.repledge_external_loan_data?.company_name
          : application?.repledge_external_loan_data?.bank_name}
      </div>
    </div>
  );

  if (isLoading) {
    return (
      <MobilePageContainer>
        <Loader variant={"large"} color={"purple"} className={styles.loader} />
      </MobilePageContainer>
    );
  }

  if (isRepledge) {
    return (
      <LoadingPage
        title={"Мы отправили"}
        withoutIcon
        subtitle={repledgeSubtitle}
        button={
          <Button
            onClick={toLk}
            className={styles.button_repledge}
            loading={isGetApplicationLoading}
          >
            Личный кабинет
          </Button>
        }
      />
    );
  }

  return (
    <LoadingPage
      title={"Деньги выданы"}
      subtitle={variant === "card" ? cardSubtitle : contactSubtitle}
      svgIcon={<Icons.ui.Stacks />}
      additionalSubtitle={
        //@ts-ignore
        // Пока бек не дает нам знать, карта или контакт. Номер карты тоже
        <>
          {/*@ts-ignore*/}
          {variant === "contact" && (
            <>
              Для получения денежных средств вам <br /> понадобится оригинал
              паспорта.
            </>
          )}
        </>
      }
      button={
        <Button
          onClick={toLk}
          className={styles.button}
          loading={isGetApplicationLoading}
        >
          Личный кабинет
        </Button>
      }
    />
  );
};
