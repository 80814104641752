import React, { FC, HTMLAttributes } from "react";
import styles from "./index.module.css";
import { Icons } from "assets/icons";
import cn from "classnames";

type propsType = {
  variant?: "large";
  color?: "white" | "purple";
} & HTMLAttributes<HTMLOrSVGElement>;
export const Loader: FC<propsType> = (props) => {
  return (
    <>
      <Icons.ui.Loader
        {...props}
        className={cn(styles.container, props.className, {
          [styles[`container--${props.variant}`]]: props.variant,
          [styles[`color--${props.color}`]]: props.color,
        })}
      />{" "}
      {props.children}
    </>
  );
};
