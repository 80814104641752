import { UserRoleType } from "../../../types/user/types";
import { paymentType } from "../../../api/main/application/types";
import { postAdvertisementTrackingType } from "../../../api/main/services/types";

export type localStorageType = {
  bank_name?: string | null;
  cost: string | null;
  user_id: number | null;
  application_id: number | null;
  token: string | null;
  phone: string | null;
  user_role: UserRoleType | null;
  amount: string | null;
  period_month: string | null;
  full_name: string | null;
  grz: string | null;
  vin: string | null;
  loan_amount: string | null;
  loan_id: string | null;
  payment_type: paymentType | null;
  is_elpts: boolean | null;
  first_name: string | null;
  last_name: string | null;
  transaction_id: string | null;
  transaction_provider: string | null;
  is_has_password: boolean;
  wasPassportMainRecognized: boolean;
  wasOptionalPhotoRecognized: boolean;
  wasPassportRegistrationRecognized: boolean;
  wasPtsFrontRecognized: boolean;
  wasPtsBackRecognized: boolean;
  wasStsFrontRecognized: boolean;
  wasStsBackRecognized: boolean;
  wasDriverLicenseFrontRecognized: boolean;
  wasDriverLicenseBackRecognized: boolean;
  webmaster_id: string;
};
export type localStorageKeysType = keyof localStorageType;
export const arrayOfObjectsInLocalStorage: Array<localStorageKeysType> = [];
