import { useQuery } from "react-query";
import { API } from "../../api";
import { Control, useWatch } from "react-hook-form";
import { passportAndInnFormType } from "../../components/pages/Application/pages/PassportAndInn/types";
import { useEffect } from "react";

// export const useGetIssuedBy = ({}: Control<passportAndInnFormType, object>) => {
export const useGetIssuedBy = ({ control, setValue }: any) => {
  const divisionCode = useWatch({
    control: control,
    name: "passport.division_code",
  });

  const issuedBy = useWatch({
    control: control,
    name: "passport.issued_by",
  });

  const getIssuedBy: any = useQuery(["getIssuedBy", divisionCode], () => {
    if (divisionCode?.length === 7 && !issuedBy) {
      return API.main.services.getDivisionCode({
        code: divisionCode,
      });
    }
  });

  useEffect(() => {
    setValue("passport.issued_by", getIssuedBy?.data?.data?.data?.value);
  }, [getIssuedBy?.data]);

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT == "stage") {
      setValue("passport.issued_by", "УФМС России");
    }
  }, []);

  return {
    isLoading: getIssuedBy?.isLoading,
    issuedBy: getIssuedBy?.data?.data?.data?.value,
  };
};
