import { FC, useEffect, useRef } from "react";
import { Controller } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import mergeRefs from "react-merge-refs";
import cn from "classnames";
import styles from "../index.module.css";
import { TextInputPropsType } from "../types";
import { InputWrapper } from "../InputWrapper";
import { useToggle } from "../../../../hooks/logical/useToggle";

export const InputBase: FC<TextInputPropsType> = ({
  variant = "default",
  ...props
}) => {
  const { inputElementRef, control, support, ...jsxAttrs } = props;

  return (
    <InputWrapper {...props}>
      <Controller
        control={props.control}
        name={props.name}
        defaultValue={props.defaultValue}
        render={({ field }) => (
          <ReactInputMask
            data-class="input"
            autoComplete="off"
            //@ts-ignore
            maskChar={null}
            mask={props.mask || ""}
            {...jsxAttrs}
            {...field}
            className={cn(styles.Input, {
              [styles.Input_error]: props.error,
              [styles.Input_readonly]: props.readOnly,
              [styles.Input_large]: variant === "large",
            })}
            inputRef={mergeRefs([field.ref, props.inputElementRef])}
            style={props.support && { paddingRight: "30px" }}
            placeholder={props.label}
            readOnly={props.readOnly}
            onPaste={props.onPaste}
            onChange={(e) => {
              if (props.onChange) {
                props.onChange(e);
              }
              field.onChange(e);
            }}
          />
        )}
      />
    </InputWrapper>
  );
};
