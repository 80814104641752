import styles from "./index.module.css";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { routes } from "../../../../../routes";
import { Icons } from "../../../../../assets/icons";
import { Button } from "../../../../ui/Button";
import { FillingAssistance } from "../../../../ui/FillingAssistance";

export const VehicleError: FC = () => {
  const history = useHistory();

  return (
    <div className={styles.container}>
      <div className={styles.title}>Ошибка</div>
      <div className={styles.icon}>
        <Icons.ui.StatusError />
      </div>
      <Button
        onClick={() => history.push(routes.auth.vehicle_valuation)}
        className={styles.button}
      >
        Вернуться на предыдущий экран
      </Button>
      <div className={styles.footer}>{/*<FillingAssistance />*/}</div>
    </div>
  );
};
